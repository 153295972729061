import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import {
  resetPassword,
  createLogin,
  getCreateNewPasswordUserEmail,
  getCreateNewPasswordUserEmailByIdAndEmail,
  postCreateNewPassword,
  activateUserAccount,
} from "../../lib/api";

export const resetPasswordThunk = createAsyncThunk<
  AxiosResponse, // response type
  string, // args type
  { rejectValue: string } // rejection response value type
>(
  "userActions/resetPassword",
  async (userName: string, { rejectWithValue }) => {
    try {
      return await resetPassword(userName);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response.data);
    }
  }
);

export const createLoginThunk = createAsyncThunk<
  AxiosResponse, // response type
  object, // args type
  { rejectValue: AxiosResponse } // rejection response value type
>("userActions/createLogin", async (submitData, { rejectWithValue }) => {
  try {
    return await createLogin(submitData);
  } catch (err) {
    let error: AxiosError<string> = err;
    return rejectWithValue(error.response);
  }
});

export const getCreateNewPasswordByResetCodeThunk = createAsyncThunk<
  AxiosResponse, // response type
  string, // args type
  { rejectValue: AxiosResponse } // rejection response value type
>(
  "userActions/getCreateNewPassword",
  async (resetCode, { rejectWithValue }) => {
    try {
      return await getCreateNewPasswordUserEmail(resetCode);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response);
    }
  }
);

type CreateNewPasswordByIdAndEmailPayload = {
  id: string;
  email: string;
}

export const getCreateNewPasswordByIdAndEmailThunk = createAsyncThunk<
  AxiosResponse, // response type
  CreateNewPasswordByIdAndEmailPayload, // args type
  { rejectValue: AxiosResponse } // rejection response value type
>(
  "userActions/getCreateNewPasswordByIdAndEmail",
  async ({ id, email }, { rejectWithValue }) => {
    try {
      return await getCreateNewPasswordUserEmailByIdAndEmail(id, email);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response);
    }
  }
);

export const postCreateNewPasswordThunk = createAsyncThunk<
  AxiosResponse, // response type
  object, // args type
  { rejectValue: string } // rejection response value type
>(
  "userActions/postCreateNewPassword",
  async (createNewPasswordData, { rejectWithValue }) => {
    try {
      return await postCreateNewPassword(createNewPasswordData);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response.data);
    }
  }
);

export const activateUserAccountThunk = createAsyncThunk<
  AxiosResponse, // response type
  string, // args type
  { rejectValue: AxiosError<any> } // rejection response value type
>(
  "userActions/activateUserAccount",
  async (activationCode, { rejectWithValue }) => {
    try {
      return await activateUserAccount(activationCode);
    } catch (err) {
      let error: AxiosError = err;
      return rejectWithValue(error);
    }
  }
);
