import Button from "components/Button/Button";
import { Status, ButtonStyles, LinkTypes } from "lib/enums";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import "../../user-company-information-page.scss";
import './accountsapprovalpage.scss';
import { useParams, useNavigate } from "react-router-dom";
import { decrypt } from "lib/helpers";
import { getLanguageUrlParam } from "localization/helpers";
import { AccountsApprovalType, InternalUserAdminAccountApprovalType } from "lib/types";
import { postInternalUserAdminAccountApprovalThunk, getAccountsApprovalThunk } from "redux/api/userDetails/actions";
import UnauthorizedBanner from "components/UnauthorizedBanner/UnauthorizedBanner";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const AccountsApprovalPage = () => {
  const isInternalUserAdmin = useAppSelector((state) => state.user.isInternalUserAdmin);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const languageUrlParam = getLanguageUrlParam();
  const dispatch = useAppDispatch();

  const { ac } = useParams();
  let activationCode = null;
  if (ac) {
    activationCode = decrypt(ac, "CDS:Account-Approval-List__Account-Code", true);
  }
  
  const accountsApprovalList = useAppSelector(
    (state) => state.userDetails.accountsApprovalListById
  );

  const internalUserAdminApprovalStateCssClass = useAppSelector(
    (state) => {
      if(state.userDetails.getInternalUserAdminApprovalStatus == Status.Rejected)
      {
        return "msds-text-header-3 msds-text-danger-red-1 mt-6 mb-4"   
      }
      return "msds-text-header-3 msds-text-confirm-green mt-6 mb-4"      
    }
  );  
  const internalUserAdminApprovalResponse = useAppSelector(
    (state) => {
      if(state.userDetails.getInternalUserAdminApprovalStatus == Status.Success)
      {
        return state.userDetails.backendResponseMessages.getInternalUserAdminApprovalResponse;
      }

      if(state.userDetails.getInternalUserAdminApprovalStatus == Status.Rejected)
      {
        return state.userDetails.backendErrorMessages.getInternalUserAdminApprovalErrorMsg;
      }

      return "";
    }
  );

  const [selectedAccount, setSelectedAccount] = useState<AccountsApprovalType>();
  useEffect(() => {
    if(isInternalUserAdmin && activationCode && selectedAccount == null ) {
      const currentAccount = Object.values(accountsApprovalList).find(account => account.activationCode === activationCode);
      setSelectedAccount(currentAccount);
    }               
  }, [accountsApprovalList, activationCode, isInternalUserAdmin]);

    const companyInformationFields = [
    { label: `${t("pages.approvalpage.company.name")}:`, field: "companyName" },
    { label: `${t("pages.approvalpage.company.address")}:`, field: "companyAddress" },
    { label: `${t("pages.approvalpage.company.zipCode")}:`, field: "companyPostalCode" },
    { label: `${t("pages.approvalpage.company.city")}:`, field: "companyCity" },
    { label: `${t("pages.approvalpage.company.country")}:`, field: "companyCountry" },
    { label: `${t("pages.approvalpage.company.state")}:`, field: "companyState" },
    { label: `${t("pages.approvalpage.company.website")}:`, field: "companyWebsite" },
    { label: `${t("pages.approvalpage.company.dnbNumber")}:`, field: "dnBNumber" },    
  ];

  const contactInformationFields = [
    { label: `${t("pages.approvalpage.contact.email")}:`, field: "email" },
    { label: `${t("pages.approvalpage.contact.firstName")}:`, field: "firstName" },
    { label: `${t("pages.approvalpage.contact.lastName")}:`, field: "lastName" },
    { label: `${t("pages.approvalpage.contact.telephone")}:`, field: "phone" },
    { label: `${t("pages.approvalpage.contact.jobTitle")}:`, field: "title" }
  ];

  const [hasUserAdminMadeAction, setHasUserAdminMadeAction] = useState(false);
  const handleChoice = (choice) => {
    const internalUserAdminApproval: InternalUserAdminAccountApprovalType = {
      activationCode: activationCode,
      approved: choice,
    }
    if(activationCode && internalUserAdminApproval) {
      dispatch(postInternalUserAdminAccountApprovalThunk(internalUserAdminApproval))
      .then(() => {
        dispatch(getAccountsApprovalThunk());
      });
    }    

    setHasUserAdminMadeAction(true);    
  };

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1 mb-8 mb-lg-10">
      <ScrollToTop />
      {isInternalUserAdmin && activationCode ? (
        selectedAccount != null ? (
          <>
            <div className="msds-text-header-2-bold">{t("pages.approvalpage.title")}</div>            
            <p className="msds-text-gray-10 msds-text-body-1">{t("pages.approvalpage.description.message1")}</p>
            <p className="msds-text-gray-10 msds-text-body-1">{t("pages.approvalpage.description.message2")}</p>   
            <div className="my-8">
              <div className="msds-text-header-3-light mb-3">
                {t("pages.approvalpage.company.title")}:
              </div>
              {selectedAccount &&
                companyInformationFields.map((data, index) => {
                  return (
                    <div key={index} className="user-information-page__data-item">
                      <p className="msds-text-body-2-bold">{data.label}</p>
                      <p className="msds-text-body-1">
                        {selectedAccount[data.field] ? selectedAccount[data.field] : "-" }
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className="mb-8">
              <div className="msds-text-header-3-light mb-3">
                {t("pages.approvalpage.contact.title")}:
              </div>
              {selectedAccount &&
                contactInformationFields.map((data, index) => {
                  return (
                    <div key={index} className="user-information-page__data-item">
                      <p key={index} className="msds-text-body-2-bold">
                        {data.label}
                      </p>
                      <span className="msds-text-body-1">
                        {selectedAccount[data.field] ? selectedAccount[data.field] : "-" }
                      </span>
                    </div>
                  );
                })}
            </div>            
            <p>{t("pages.approvalpage.description.message3")}</p>
            {hasUserAdminMadeAction && (
              <div className={internalUserAdminApprovalStateCssClass}>{internalUserAdminApprovalResponse}</div>
            )}          
            <div className="user-information-page__actions">
              <Button
                label={t("buttons.back")}              
                styling={ButtonStyles.Secondary}
                onClickHandler={() => {
                  navigate(`${languageUrlParam}/accounts-approval-list`);
                }}
              />                      
              <Button
                label={t("buttons.approve")}
                styling={ButtonStyles.Tertiary}
                eventCategory="User Portal"
                eventLabel="Sign-up Form"
                onClickHandler={() => { handleChoice(true) }}
                buttonType = {LinkTypes.Button}
                disabled={hasUserAdminMadeAction}
                />
                <Button
                label={t("buttons.decline")}
                styling={ButtonStyles.Secondary}
                eventCategory="User Portal"
                eventLabel="Sign-up Form"
                onClickHandler={() => { handleChoice(false) }}
                buttonType = {LinkTypes.Button}
                disabled={hasUserAdminMadeAction}
                />
            </div>
          </>  
        ) : (
          <div className="col-10 offset-1 col-lg-6 offset-lg-1 mb-8 mb-lg-10">
            <div className="msds-text-header-2-bold">
              {t("pages.approvalpage.title")}
            </div>            
            <div className="msds-text-header-3-light">
              {t("pages.approvalpage.description.message4")}
            </div>                                  
            <div className="user-information-page__actions">
              <Button
                label={t("buttons.back")}              
                styling={ButtonStyles.Secondary}
                onClickHandler={() => {
                  navigate(`${languageUrlParam}/accounts-approval-list`);
                }}
              />            
            </div>  
          </div>  
        )
        ) :
        (
          <UnauthorizedBanner />
        )}      
    </div>
  )
};

export default AccountsApprovalPage;
