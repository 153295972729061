import CompanyDataForm from "components/EnterCompanyDataForm/EnterCompanyDataForm";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { resetbackendErrors } from "redux/signup/signupSlice";

const EnterCompanyData = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const regioncountries = useAppSelector(
    (state) => state.configutationLists.RegionCountries
  );

  useEffect(() => {
    return () => {
      dispatch(resetbackendErrors());
    };
  }, []);

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <ScrollToTop />
      <div className="msds-text-header-2-bold">
        {t("pages.signUp.title")}
      </div>            
      <p className="msds-text-gray-10 msds-text-body-1">
        <Trans i18nKey="pages.signUp.alreadyHaveAccount">
          Already have an account?
          <a href="/">Log in here</a>
        </Trans>
      </p>
      <CompanyDataForm regioncountries={regioncountries} />
    </div>
  );
};

export default EnterCompanyData;
