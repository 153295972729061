import { useEffect, useContext } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import Routing from "../../routing/Routing";
import LoaderOverlay from "../LoaderOverlay/LoaderOverlay";
import { setLanguageUrlParamOnLoad } from "../../localization/helpers";
import { getMyDetailsThunk } from "redux/api/userDetails/actions";
import { getMySubscriptionThunk } from "redux/api/subscription/actions";
import { getUserDetailsCompanyThunk } from "redux/api/userDetails/actions";
import { getAccountsApprovalThunk } from "redux/api/userDetails/actions";
import { getUserRolesThunk } from "redux/userRoles/userRolesSlice";
import { getConfigurationListsThunk } from "redux/configurationLists/actions";
import { getLicensesThunk } from "redux/licenses/actions";
import { selectIsAuthenticated } from "redux/user/userSlice";
import { AuthenticationContext } from "components/Authentication/AuthenticationProvider";
import { useHideNavBaseOnLocation } from "lib/hooks";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import "@milestone-sys/web-design-system/main.css";
import "./app.scss";

function App() {
    const dispatch = useAppDispatch();
    const isAdmin = useAppSelector((state) => state.user.isAdmin);
    const authenticationContext = useContext(AuthenticationContext);
    const isNavigationHidden = useHideNavBaseOnLocation()
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const isInternalUserAdmin = useAppSelector((state) => state.user.isInternalUserAdmin);
    const isLoading: boolean = useAppSelector((state) => state.app.isLoading);

    useEffect(() => {
        if (isAuthenticated) {            
            dispatch(getMyDetailsThunk());
            dispatch(getMySubscriptionThunk());
            dispatch(getLicensesThunk());
        }

        if(isAdmin) {
            dispatch(getUserDetailsCompanyThunk());            
            dispatch(getUserRolesThunk());
        }
        
        if (isInternalUserAdmin) {
            dispatch(getAccountsApprovalThunk());
        }
    }, [isAuthenticated, isAdmin, isInternalUserAdmin]);

    useEffect(() => {
        dispatch(getConfigurationListsThunk("RegionCountries"));
        dispatch(getConfigurationListsThunk("CountryTelephoneCodes"));
        dispatch(getConfigurationListsThunk("PreferredLanguages"));
        dispatch(getConfigurationListsThunk("USStates"));
        dispatch(getConfigurationListsThunk("IndiaStates"));
        dispatch(getConfigurationListsThunk("CanadaStates"));
        dispatch(getConfigurationListsThunk("AustraliaStates"));
    }, [dispatch]);

    useEffect(() => {
        setLanguageUrlParamOnLoad();
    }, []);

    return (
        <>
            <LoaderOverlay isLoading={isLoading} />
            {!isNavigationHidden && <Header onLoginButtonClick={authenticationContext.handleLoginButtonClick} />}
            <Routing />
            <Footer />
        </>
    );
}

export default App;