import React, { useState, useRef, useEffect } from 'react';
import Device from './device';
import { NavLink } from 'react-router-dom';
import './popover.scss'

const SignedInMenu = ({ onLogoutClick }) => {

  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const device = Device();
  const popoverRef = useRef<HTMLDivElement>(null);
  const goToButtonRef = useRef<HTMLDivElement>(null);
  
  const handleGotoButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    togglePopoverDisplay(); 
  }

  const handleClickOutsidePopover = (e: MouseEvent) => {    
    if (
        popoverRef.current && // Ensure ref is defined
        !popoverRef.current.contains(e.target as Node) && // Check if the click is outside the popover
        goToButtonRef.current && // Ensure ref is defined
        !goToButtonRef.current.contains(e.target as Node) // Check if the click is outside the toggle button
      ) {        
        setPopoverVisible(false);
      }
  };

  const handleWindowResize = () => {
    setPopoverVisible(false);
  };

  const togglePopoverDisplay = () => {    
    setPopoverVisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (isPopoverVisible) {
      document.addEventListener('mousedown', handleClickOutsidePopover);
    } else {
      document.removeEventListener('mousedown', handleClickOutsidePopover);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePopover);
    };
  }, [isPopoverVisible]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    // Cleanup event listener on component unmount
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
}, [])
  return (
    <>
      <div className="header-section-block__login-button" ref={goToButtonRef}>
        <a 
          className="button-tile-block msds-btn msds-btn--secondary msds-btn--sm"
          aria-expanded={isPopoverVisible ? 'true' : 'false'}
          onClick={handleGotoButtonClick}>
          Go To
        </a>
      </div>
      {isPopoverVisible && (
        <div className="popover" ref={popoverRef}>
          <div className="popover__arrow"></div>
          <ul className='popover__list'>
          <li className='popover__list-item'>
                            <a href="https://partner.milestonesys.com/" target="_blank" rel="noopener noreferrer">
                                Reseller & Distributor Portal
                            </a>
                        </li>
                        <li className='popover__list-item'>
                            <a href="https://www.milestonesys.com/my-milestone/?epslanguage=en" target="_blank" rel="noopener noreferrer">
                                Technology Partner & A&E Portal
                            </a>
                        </li>
                        <li className='popover__list-item'>
                            <a href="https://www.milestonesys.com/my-milestone/?epslanguage=en" target="_blank" rel="noopener noreferrer">
                                Customer Portal
                            </a>
                        </li>
                        <li className='popover__list-item'>
                            <a href="https://www.milestonesys.com/my-milestone/technology-partner-finder-dashboard/" target="_blank" rel="noopener noreferrer">
                                Technology Partner Finder
                            </a>
                        </li>
                        <li className='popover__list-item'>
                            <a href="https://www.milestonesys.com/products/software/arcules/arcules-login/" target="_blank" rel="noopener noreferrer">
                                Arcules Login
                            </a>
                        </li>
            <li className='popover__list-item' onClick={onLogoutClick}>Log Out</li>
          </ul>
        </div>
      )}
  </>
  )
}

export default SignedInMenu;
