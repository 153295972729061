import Button from "components/Button/Button";
import { ButtonStyles, Status } from "../../lib/enums";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { activateUserAccountThunk } from "redux/userActions/actions";
import { useTranslation } from "react-i18next";
import { MILESTONE_URL } from "config";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const UserAccountActivationPage = () => {
  const { t } = useTranslation();
  const { activationCode } = useParams();
  const dispatch = useAppDispatch();

  const activateUserAccountStatus = useAppSelector(
    (state) => state.userActions.activateUserAccountStatus
  );

  const backendErrorMessage = useAppSelector(
    (state) => state.userActions.backendErrors.activateUserAccountErrorMsg
  );

  const accountPendingApproval = useAppSelector((state) => state.userActions.accountPendingApproval);

  const handleActivateAccountClick = () => {
    dispatch(activateUserAccountThunk(activationCode));
  };

  const header =
    activateUserAccountStatus === Status.Success
      ? t("pages.userAccountActivation.titleSuccess")
      : t("pages.userAccountActivation.title");

  const description =
    activateUserAccountStatus === Status.Success ? (
      <p className="msds-text-gray-10 msds-text-body-1">
        {t("pages.userAccountActivation.descriptionSuccess")}
      </p>
    ) : (
      <>
        <p className="msds-text-gray-10 msds-text-body-1">
          {t("pages.userAccountActivation.description.message1")}
        </p>
        <p className="msds-text-gray-10 msds-text-body-1">
          {t("pages.userAccountActivation.description.message2")}
        </p>
      </>
    );

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <ScrollToTop />
      <div className="msds-text-header-2-bold">{header}</div>      
      <p className="msds-text-gray-10 msds-text-body-1">
        {description}
      </p>
      <div className="msds-input msds-input--error">
        <p className="msds-input__info-message">
          {backendErrorMessage}
        </p>
        {(activateUserAccountStatus === Status.Success) ? (
          <Button
            label={t("buttons.goToFrontPage")}
            styling={ButtonStyles.Secondary}
            href={`${MILESTONE_URL()}`}
          />
        ) : (activateUserAccountStatus === Status.Rejected) ? (
          <Button
            label={
              accountPendingApproval
                ? t("buttons.goToFrontPage")
                : t("buttons.goToLoginPage")
            }
            styling={ButtonStyles.Secondary}
            href={
              accountPendingApproval
                ? `${MILESTONE_URL()}`
                : `${MILESTONE_URL()}/my-milestone/`
            }
          />
        ) : (
          <Button
            label={t("buttons.verifyEmail")}
            styling={ButtonStyles.Secondary}
            onClickHandler={handleActivateAccountClick}
          />
        )}
      </div>
    </div>
  );
};

export default UserAccountActivationPage;
