
const BurgerMenu = function () {
    let navigation;
    let navigationMenuItems;
    let bodyElement;
    let burgerMenuLink;
    let closeButtonElement;

    //Initialize the burger menu events
    function initialize() {
        cacheDom();
        bindEvents();
    }

    function cacheDom() {
        bodyElement = document.querySelector('body');
        navigation = document.querySelector('#MainMenu');
        burgerMenuLink = document.querySelector('.header-section-block__links-burger-menu-link');
        closeButtonElement = navigation ? navigation.querySelector('.header-section-block__navigation-menus-button-close') : null;

        if (navigation) {            
            navigationMenuItems = navigation.querySelectorAll('.header-section-block__navigation-menu');
        }
    }

    function bindEvents() {
        if (burgerMenuLink) {
            burgerMenuLink.addEventListener("click", burgerMenuLinkToggle);
        }
     
        if (closeButtonElement) {
            closeButtonElement.addEventListener("click", function () {              
                closeBurgerMenu();
                updatePageScrolling();
            }, false);
        }
    }

    function burgerMenuLinkToggle() {        
        burgerMenuLink.classList.toggle('header-section-block__links-burger-menu-link--active');
        navigation.classList.toggle('header-section-block__navigation--show');
        updatePageScrolling(); 
    }

    function bindBurgerMenuClick() {
        if (navigationMenuItems) {
            navigationMenuItems.forEach(function (navigationMenuItem) {
                if (navigationMenuItem.classList.contains('header-section-block__navigation-menu--level1') ||
                    navigationMenuItem.classList.contains('header-section-block__navigation-menu--level2')) {
                    navigationMenuItem.addEventListener("click", onBurgerMenuClick, false);
                }
                else {
                    const navigationMenuItemIcon = navigationMenuItem.querySelector('.header-section-block__navigation-menu-label-icon');
                    if (navigationMenuItemIcon) {
                        navigationMenuItemIcon.addEventListener("click", onBurgerMenuIconClick, false);
                    }
                }
            });
        }
    }

    function unbindBurgerMenuClick() {
        if (navigationMenuItems) {
            navigationMenuItems.forEach(function (navigationMenuItem) {
                if (navigationMenuItem.classList.contains('header-section-block__navigation-menu--level1') ||
                    navigationMenuItem.classList.contains('header-section-block__navigation-menu--level2')) {
                    navigationMenuItem.removeEventListener("click", onBurgerMenuClick, false);
                }
                else {
                    const navigationMenuItemIcon = navigationMenuItem.querySelector('.header-section-block__navigation-menu-label-icon');
                    if (navigationMenuItemIcon) {
                        navigationMenuItemIcon.removeEventListener("click", onBurgerMenuIconClick, false);
                    }
                }
            });           
        }
    }

    function onBurgerMenuClick() {
        this.classList.toggle('header-section-block__navigation-menu--active');
    }

    function onBurgerMenuIconClick() {
        this.closest('.header-section-block__navigation-menu').classList.toggle('header-section-block__navigation-menu--active');
    }

    function onOutsideBurgerMenuClick(event) {
        
        if (event) {
            //Check if the click is inside the burger menu. if not then we close the mega menu and reset any level 1 active menu. 
            if (event.target.closest(".header-section-block__links-burger-menu-link") ||
                event.target.closest(".header-section-block__navigation-menus")) {
                return false;
            }

            return true;
        }
    }

    function closeBurgerMenu() {
        if (burgerMenuLink.classList.contains('header-section-block__links-burger-menu-link--active')) {
            burgerMenuLink.classList.remove('header-section-block__links-burger-menu-link--active');
        }
        if (navigation.classList.contains('header-section-block__navigation--show')) {
            navigation.classList.remove('header-section-block__navigation--show');
        }
        if (navigation) {
            navigation.querySelectorAll('.header-section-block__navigation-menu').forEach(function (element) {
                element.classList.remove('header-section-block__navigation-menu--active');
            });
        }
    }

    function onBurgerMenuWindowResize() {
        closeBurgerMenu();
    }

    function updatePageScrolling() {
        if (isOpened() && !bodyElement.classList.contains('no-scroll')) {
            bodyElement.classList.add('no-scroll');
        }
        if (!isOpened() && bodyElement.classList.contains('no-scroll')) {
            bodyElement.classList.remove('no-scroll');
        }
    }

    function isOpened() {
        return burgerMenuLink.classList.contains('header-section-block__links-burger-menu-link--active');
    }

    return {
        initialize: initialize,
        bindBurgerMenuClick: bindBurgerMenuClick,
        unbindBurgerMenuClick: unbindBurgerMenuClick,
        onOutsideBurgerMenuClick: onOutsideBurgerMenuClick,
        onBurgerMenuWindowResize: onBurgerMenuWindowResize,
        closeBurgerMenu: closeBurgerMenu,
        isOpened: isOpened
    }
}

export default BurgerMenu;