import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import MatchesFound from "./MatchesFound/MatchesFound";
import { postCompanyVerificationThunk } from "redux/companyVerification/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { matchesFound } from "./helpers";
import NoMatchesFound from "./NoMatchesFound/NoMatchesFound";
import { Status } from "lib/enums";
import FormConfirmation from "components/FormConfirmation/FormConfirmation";
import VerifyCompanyConfirmation from "./VerifyCompanyConfirmation/VerifyCompanyConfirmation";
import "./verify-company-page.scss";
import { getRegisterLicenseFlowDataFromSessionStorage, toggleRegisterLicenseInSessionStorage } from "lib/helpers";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const VerifyCompanyPage = () => {
  const dispatch = useAppDispatch();  
  const {search} = useLocation();
  const { contactId } = useParams();
  const navigate = useNavigate();
  const [isMyCompanyOnTheList, setIsMyCompanyOnTheList] =  useState<boolean>(true);
  const companyVerificationData = useAppSelector(
    (state) => state.companyVerification.data
  );

  const updateCompanyWithDunsNumberStatus = useAppSelector((state) => state.companyVerification.updateCompanyWithDunsNumberStatus);

  const fetchedCompanyVerificationData = companyVerificationData != null;

  const backendError = useAppSelector(
    (state) =>
      state.companyVerification.backendErrors.postCompanyVerificationErrorMsg
  );
  const registerLicenseFlowData = getRegisterLicenseFlowDataFromSessionStorage()
  
  useEffect(() => {  
    dispatch(postCompanyVerificationThunk({contactId}));

    if(updateCompanyWithDunsNumberStatus === Status.Success && registerLicenseFlowData?.isRegisterLicenseFlow){
      toggleRegisterLicenseInSessionStorage();
      
      window.location.href = `/register-license/slc=${registerLicenseFlowData.slc}&token=${registerLicenseFlowData.token}`      
    }
    
  }, [dispatch, contactId, updateCompanyWithDunsNumberStatus] );
  
  if (fetchedCompanyVerificationData && matchesFound(companyVerificationData)) {
    if (updateCompanyWithDunsNumberStatus === Status.Success){
      
      return <FormConfirmation content={<VerifyCompanyConfirmation />} />;
    }
      
    if (!isMyCompanyOnTheList)
      return <NoMatchesFound 
                userData={companyVerificationData} 
                handleIsMyCompanyOnTheList={setIsMyCompanyOnTheList} 
                matchesWereFound={true}
              />;
    return (
      <MatchesFound      
        accountId={companyVerificationData.accountId}
        contactEmail={companyVerificationData.contactEmail}
        matchCandidates={companyVerificationData.matchCandidates}
        handleIsMyCompanyOnTheList={setIsMyCompanyOnTheList}
      />
    );
  }

  if (
    fetchedCompanyVerificationData &&
    !matchesFound(companyVerificationData)
  ) {
    return <NoMatchesFound 
              userData={companyVerificationData} 
              handleIsMyCompanyOnTheList={setIsMyCompanyOnTheList}  
              matchesWereFound={false}
            />;
  }

  return (
    <>
      <ScrollToTop />
      <div className="searching-matches">
        <div className="msds-input msds-input--error">
          <p className="msds-input__info-message">{backendError}</p>
        </div>
      </div>
    </>
  );
};

export default VerifyCompanyPage;
