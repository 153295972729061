import { useEffect, useState } from "react";
import Button from "components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateMySubscriptionThunk } from "redux/api/subscription/actions";
import { resetUpdateSubscriptionStatus } from "redux/api/subscription/subscriptionSlice";
import { UpdateSubscriptionType } from "lib/types";
import { ButtonSize, ButtonStyles, Status } from "lib/enums";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { MILESTONE_URL } from "config";

const MyDetailsSubscriptionSection = () => {
  const dispatch = useAppDispatch();

  const [updateMessage, setUpdateMessage] = useState("");

  const isSubscribed = useAppSelector(
    (state) => state.subscription.isSubscribed
  );

  const updateMySubscriptionStatus = useAppSelector(
    (state) => state.subscription.updateMySubscriptionStatus
  );

  const privacyNoticeText = t("forms.privacyNotice");

  const handleButtonClick = () => {
    const consentText = isSubscribed
      ? "Prospect has unsubscribed on My Milestone"
      : "Prospect has subscribed on My Milestone";

    const updateSubscriptionData: UpdateSubscriptionType = {
      subscribed: !isSubscribed, // if button is clecked always give the opposite boolean value compared to the existing one
      privacyNoticeText: privacyNoticeText,
      consentText: consentText,
      currentPagePathAndQuery: "/edit-user-details",
    };
    dispatch(updateMySubscriptionThunk(updateSubscriptionData));
  };

  const updateMySubscriptionErrorMessage = useAppSelector(
    (state) =>
      state.subscription.backendErrorMessages.updateMySubscriptionErrorMsg
  );

  useEffect(() => {
    if (updateMySubscriptionStatus === Status.Success) {
      isSubscribed
        ? setUpdateMessage(
            t("pages.myDetails.stayInformedSection.subscribedSuccess")
          )
        : setUpdateMessage(
            t("pages.myDetails.stayInformedSection.unsubscribedSuccess")
          );

      dispatch(resetUpdateSubscriptionStatus());
    }
  }, [updateMySubscriptionStatus, isSubscribed]);

  useEffect(() => {
    let timeId;
    if (updateMessage) {
      timeId = setTimeout(() => {
        // After 4 seconds set the message to empty
        setUpdateMessage("");
      }, 4000);
    }

    return () => {
      if (timeId) clearTimeout(timeId);
    };
  }, [updateMessage]);

  return (
    <>
      <h3 className="msds-text-header-2 mt-0 mb-6">
        {t("pages.myDetails.stayInformedSection.title")}
      </h3>
      <div className="user-portal__edit-user-details-subscription">
        {isSubscribed === true && (
          <>
            <p>
              <Trans
                i18nKey={
                  "pages.myDetails.stayInformedSection.subscribedMessageRich"
                }
              >
                You are currently opted-in to receive marketing communication by
                emails and calls from
                <a
                  target="_blank"
                  href={`${MILESTONE_URL()}/company-news/milestone/about-us/`}
                >
                  Milestone Group Companies
                </a>
                . You can unsubscribe at any time.
              </Trans>
            </p>
          </>
        )}
        {isSubscribed === false && (
          <>
            <p className="msds-text-gray-10 msds-text-body-1">
              <Trans
                components={{
                  companies: (
                    <a
                      key="href-1"
                      href={`${MILESTONE_URL()}/company-news/milestone/about-us/`}
                    />
                  ),
                  partners: (
                    <a
                      key="href-2"
                      href={`${MILESTONE_URL()}/community/become-a-partner/`}
                    />
                  ),
                  unsubscribe: (
                    <a
                      key="href-3"
                      href="https://pardot.milestonesys.com/preferences?epc_hash=FHmZ_64RVPy5bNsGooMMwzaJbwHXRSUV_Qu1b1rElEo"
                    />
                  ),
                }}
              >
                {t(
                  "pages.myDetails.stayInformedSection.unsubscribedMessageRich"
                )}
              </Trans>
            </p>
            <p className="msds-text-gray-10 msds-text-body-1">
              <Trans
                components={{
                  anchor: <a href={`${MILESTONE_URL()}/privacy-policy/`} />,
                }}
              >
                {t("forms.privacyNoticeRich")}
              </Trans>
            </p>
          </>
        )}
        {updateMySubscriptionStatus === Status.Rejected && (
        <div className="msds-text-danger-red-1">
          {updateMySubscriptionErrorMessage
            ? updateMySubscriptionErrorMessage
            : "An error occurred, please try again later."}
        </div>
      )}
        <Button
          label={
            isSubscribed ? t("buttons.unsubscribeAll") : t("buttons.contactMe")
          }
          size={ButtonSize.Large}
          styling={ButtonStyles.Secondary}
          onClickHandler={handleButtonClick}
        />
        {updateMessage && (
          <div className="d-inline-block pl-6 msds-text-confirm-green">
            {updateMessage}
          </div>
        )}
      </div>
    </>
  );
};

export default MyDetailsSubscriptionSection;
