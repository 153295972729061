export enum Status {
  Idle = "idle",
  Pending = "pending",
  Rejected = "rejected",
  Success = "success",
}

export enum ButtonStyles {
  Primary = "msds-btn--primary",
  Secondary = "msds-btn--secondary",
  SecondaryDark = "msds-btn--secondary-dark",
  Tertiary = "msds-btn--tertiary",
  Gray = "msds-btn--gray",
  Danger = "msds-btn--danger",
}

export enum ButtonSize {
  Large = "msds-btn--lg",
  Medium = "",
  Small = "msds-btn--sm",
}

export enum FloatingButtonSize {
  Large = "msds-btn-floating--lg",
  Medium = "",
  Small = "msds-btn-floating--sm",
}

export enum FloatingButtonStyles {
  Primary = "msds-btn-floating",
  Secondary = "msds-btn-floating--secondary",
  SecondaryDark = "msds-btn-floating--dark"
}

export enum UserRoles {
  MyMilestoneAdmin = "MyMilestone Admin",
  InternalUserAdmin = "Internal User Admin",
}

export enum SignupSteps {
  EnterCompanyData,
  SelectCompany,
  ContactSupport,
  EnterUserData,
  OverviewAndSubmission,
  SignupConfirmation,
  ContactSupportConfirmation,
}

export enum LinkTypes {
  Hyperlink,
  Button
}