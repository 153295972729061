import { Trans, useTranslation } from "react-i18next";
import Button from "components/Button/Button";
import { ButtonSize, ButtonStyles } from "lib/enums";
import { MILESTONE_URL } from "config";

const ContactSupportConfirmation = () => {
  const { t } = useTranslation();

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">        
      <div className="msds-text-header-2-bold">
        { t("pages.signUp.noMatch.titleContactSupportConfirmation") }
      </div>      
      <p className="msds-text-gray-10 msds-text-body-1">
        {t(
          "pages.signUp.noMatch.descriptionContactSupportConfirmation.message1"
        )}
      </p>
      <p className="msds-text-gray-10 msds-text-body-1">
        {t(
          "pages.signUp.noMatch.descriptionContactSupportConfirmation.message2"
        )}
      </p>
      <p className="msds-text-gray-10 msds-text-body-1">
        <Trans
          components={{
            support: (
              <a 
                target="_blank"
                href={`${MILESTONE_URL()}/support/contact-us/sales-support/`}
              />
            ),
          }}
        >
          {t(
            "pages.signUp.noMatch.descriptionContactSupportConfirmation.message3"
          )}
        </Trans>
      </p>
      <Button
        href={`${MILESTONE_URL()}/my-milestone/`}
        label={t("buttons.goToMyMilestone")}
        size={ButtonSize.Medium}
        styling={ButtonStyles.Secondary}
      />
    </div>      
  );
};

export default ContactSupportConfirmation;
