import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Button from "../../components/Button/Button";
import { ButtonStyles, ButtonSize } from "../../lib/enums";
import { getLanguageUrlParam } from "localization/helpers";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

function CookiePolicyPage() {    
    const navigate = useNavigate();
    const backEventClick = () => {
        navigate(-1);
    };   
    const languageUrlParam = getLanguageUrlParam();

    // Add the Cookiebot declaration script
    useEffect(() => {       
        const script = document.createElement("script");
        script.id = "CookieDeclaration"
        script.src = "https://consent.cookiebot.com/71f1dae0-4e8b-4339-85e2-b285baec57ce/cd.js";
        script.setAttribute("data-culture", "en");
        script.async = true;
        
        const cookiebotContainer = document.getElementById("cookie-declaration-container");
        cookiebotContainer.appendChild(script);
        return () => {
            cookiebotContainer.removeChild(script);
        };
    });

    return (
        <div className="col-10 offset-1 col-lg-6 offset-lg-1">
            <ScrollToTop />
            <div className="msds-text-header-2-bold">Cookie Policy</div>
            <Button
                id="user-portal__e2e-cookie-footer-link"
                label="Back"
                onClickHandler={backEventClick}
                size={ButtonSize.Large}
                styling={ButtonStyles.Secondary}
                cssClass="my-7"
            />                    
            <div id="cookie-declaration-container"></div>
        </div>    
    );
}

export default CookiePolicyPage;