import { createSlice } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import { AccountsApprovalType, CompanyUserType } from "lib/types";
import {
  getMyDetailsThunk as getMyDetails,
  updateMyDetailsDataThunk as updateMyDetailsData,
  getUserDetailsCompanyThunk as getUserDetailsCompany,
  getAccountsApprovalThunk as getAccountsApproval,
  postInternalUserAdminAccountApprovalThunk as postInternalUserAdminAccountApproval,
  updateExistingUserDataThunk as updateExistingUserData,
  updateMyPasswordThunk as updateMyPassword,
} from "./actions";

type UserDetailsReducerState = {
  myDetails: CompanyUserType;
  companyUsers: string[];
  companyUsersById: { [key: string]: CompanyUserType };
  accountsApprovalList: string[];  
  accountsApprovalListById: { [key: string]: AccountsApprovalType };
  getMyDetailsStatus: Status;
  updateMyDetailsDataStatus: Status;
  updateMyPasswordStatus: Status;
  getUserDetailsCompanyStatus: Status;
  getAccountsApprovalStatus: Status;
  getInternalUserAdminApprovalStatus: Status;
  updateExistingUserDataStatus: Status;
  backendErrorMessages: {
    getMyDetailsErrorMsg: string;
    updateMyDetailsDataErrorMsg: string;
    updateMyPasswordErrorMsg: string;
    getUserDetailsCompanyErrorMsg: string;
    getAccountsApprovalErrorMsg: string;    
    getInternalUserAdminApprovalErrorMsg: string;
    updateExistingUserDataErrorMsg: string;
  };
  backendResponseMessages: {
    getInternalUserAdminApprovalResponse: string;
  }
};

const initialState: UserDetailsReducerState = {
  myDetails: null,
  companyUsers: [],
  companyUsersById: {},
  accountsApprovalList: [],
  accountsApprovalListById: {},
  getMyDetailsStatus: Status.Idle,
  updateMyDetailsDataStatus: Status.Idle,
  updateMyPasswordStatus: Status.Idle,
  getUserDetailsCompanyStatus: Status.Idle,
  getAccountsApprovalStatus: Status.Idle,
  getInternalUserAdminApprovalStatus: Status.Idle,
  updateExistingUserDataStatus: Status.Idle,
  backendErrorMessages: {
    getMyDetailsErrorMsg: "",
    updateMyDetailsDataErrorMsg: "",
    updateMyPasswordErrorMsg: "",
    getUserDetailsCompanyErrorMsg: "",
    getAccountsApprovalErrorMsg: "",
    getInternalUserAdminApprovalErrorMsg: "",
    updateExistingUserDataErrorMsg: "",
  },
  backendResponseMessages: {
    getInternalUserAdminApprovalResponse: ""
  }
};

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    resetUpdateExistingUserDataStatus: (state) => {
      state.updateExistingUserDataStatus = Status.Idle;
      state.backendErrorMessages.updateExistingUserDataErrorMsg = "";
    },
    resetUpdateMyPasswordStatus: (state) => {
      state.updateMyPasswordStatus = Status.Idle;
      state.backendErrorMessages.updateMyPasswordErrorMsg = "";
    },
    resetUpdateMyDetailsDataStatus: (state) => {
      state.updateMyDetailsDataStatus = Status.Idle;
      state.backendErrorMessages.updateMyDetailsDataErrorMsg = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyDetails.pending, (state) => {
        state.getMyDetailsStatus = Status.Pending;
      })
      .addCase(getMyDetails.rejected, (state, action) => {
        state.getMyDetailsStatus = Status.Rejected;
        state.backendErrorMessages.getMyDetailsErrorMsg = action.payload;
      })
      .addCase(getMyDetails.fulfilled, (state, action) => {
        state.getMyDetailsStatus = Status.Success;
        state.backendErrorMessages.getMyDetailsErrorMsg = "";
        state.myDetails = action.payload;
      })
      .addCase(updateMyDetailsData.pending, (state) => {
        state.updateMyDetailsDataStatus = Status.Pending;
      })
      .addCase(updateMyDetailsData.rejected, (state, action) => {
        if (typeof action.payload === "string") {
          state.backendErrorMessages.updateMyDetailsDataErrorMsg =
            action.payload;
        } else {
          state.backendErrorMessages.updateMyDetailsDataErrorMsg =
            "An error occurred while processing your request.";
        }
        state.updateMyDetailsDataStatus = Status.Rejected;
      })
      .addCase(updateMyDetailsData.fulfilled, (state, action) => {
        const updatedMyDetails = state.myDetails as CompanyUserType;
        updatedMyDetails.firstName = action.meta.arg.firstName;
        updatedMyDetails.lastName = action.meta.arg.lastName;
        updatedMyDetails.jobTitle = action.meta.arg.jobTitle;
        updatedMyDetails.telephone = action.meta.arg.telephone;
        updatedMyDetails.mobile = action.meta.arg.mobile;
        updatedMyDetails.prefferedLanguageId =
          action.meta.arg.prefferedLanguageId;
        updatedMyDetails.email = action.meta.arg.email;
        updatedMyDetails.username = action.meta.arg.username;
        state.backendErrorMessages.updateMyDetailsDataErrorMsg = "";
        state.updateMyDetailsDataStatus = Status.Success;
      })
      .addCase(updateMyPassword.pending, (state) => {
        state.updateMyPasswordStatus = Status.Pending;
      })
      .addCase(updateMyPassword.rejected, (state, action) => {
        state.updateMyPasswordStatus = Status.Rejected;
        state.backendErrorMessages.updateMyPasswordErrorMsg = action.payload;
      })
      .addCase(updateMyPassword.fulfilled, (state) => {
        state.updateMyPasswordStatus = Status.Success;
        state.backendErrorMessages.updateMyPasswordErrorMsg = "";
      })
      .addCase(getUserDetailsCompany.pending, (state) => {
        state.getUserDetailsCompanyStatus = Status.Pending;
      })
      .addCase(getUserDetailsCompany.rejected, (state, action) => {
        state.getUserDetailsCompanyStatus = Status.Rejected;
        state.backendErrorMessages.getUserDetailsCompanyErrorMsg =
          action.payload;
      })
      .addCase(getUserDetailsCompany.fulfilled, (state, action) => {
        state.getUserDetailsCompanyStatus = Status.Success;
        const ids = action.payload.map((companyUser) => companyUser.id);
        const companyUsers = {};
        action.payload.forEach((companyUser) => {
          companyUsers[companyUser.id] = companyUser;
        });
        state.companyUsers = ids;
        state.companyUsersById = companyUsers;
      })
      .addCase(updateExistingUserData.pending, (state) => {
        state.updateExistingUserDataStatus = Status.Pending;
      })
      .addCase(updateExistingUserData.rejected, (state, action) => {
        if (typeof action.payload === "string") {
          state.backendErrorMessages.updateExistingUserDataErrorMsg =
            action.payload;
        } else {
          state.backendErrorMessages.updateExistingUserDataErrorMsg =
            "An error occurred while processing your request.";
        }
        state.updateExistingUserDataStatus = Status.Rejected;
      })
      .addCase(updateExistingUserData.fulfilled, (state, action) => {
        const updatedUserId = action.meta.arg.id;
        const updatedUser = state.companyUsersById[updatedUserId];
        updatedUser.firstName = action.meta.arg.firstName;
        updatedUser.lastName = action.meta.arg.lastName;
        updatedUser.telephone = action.meta.arg.phone;
        updatedUser.mobile = action.meta.arg.mobilePhone;
        updatedUser.userRoles = action.meta.arg.roles;
        updatedUser.noLongerWithCompany = action.meta.arg.noLongerWithCompany ? "true" : "false";
        state.updateExistingUserDataStatus = Status.Success;
      })
      .addCase(getAccountsApproval.pending, (state) => {
        state.getAccountsApprovalStatus = Status.Pending;
      })
      .addCase(getAccountsApproval.rejected, (state, action) => {
        state.getAccountsApprovalStatus = Status.Rejected;
        state.backendErrorMessages.getAccountsApprovalErrorMsg = action.payload;
      })
      .addCase(getAccountsApproval.fulfilled, (state, action) => {
        state.getAccountsApprovalStatus = Status.Success;

        const activationCodes = action.payload.map((account) => account.activationCode);
        const accounts = {};
        action.payload.forEach((account) => {
          accounts[account.activationCode] = account;
        });
        state.accountsApprovalList = activationCodes;
        state.accountsApprovalListById = accounts;
      })
      .addCase(postInternalUserAdminAccountApproval.pending, (state) => {
        state.getInternalUserAdminApprovalStatus = Status.Pending;
      })
      .addCase(postInternalUserAdminAccountApproval.rejected, (state, action) => {
        state.getInternalUserAdminApprovalStatus = Status.Rejected;        
        state.backendErrorMessages.getInternalUserAdminApprovalErrorMsg = action.payload;        
      })
      .addCase(postInternalUserAdminAccountApproval.fulfilled, (state, action) => {
        state.getInternalUserAdminApprovalStatus = Status.Success;
        state.backendResponseMessages.getInternalUserAdminApprovalResponse = action.payload.data;
      })      
      ;
  },
});

export default userDetailsSlice.reducer;

export const {
  resetUpdateExistingUserDataStatus,
  resetUpdateMyPasswordStatus,
  resetUpdateMyDetailsDataStatus,
} = userDetailsSlice.actions;
