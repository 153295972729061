import { useEffect } from "react";
import UserDetailsForm from "../../components/UserDetailsForm/UserDetailsForm";
import { useParams, useNavigate } from "react-router-dom";
import { decrypt } from "lib/helpers";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import Button from "components/Button/Button";
import { ButtonSize, ButtonStyles, Status } from "../../lib/enums";
import { resetUpdateExistingUserDataStatus } from "../../redux/api/userDetails/userDetailsSlice";
import { resetCreateUserTemporaryProfileStatus } from "../../redux/api/userTemporaryProfile/userTemporaryProfileSlice";
import FormConfirmation from "components/FormConfirmation/FormConfirmation";
import UnauthorizedBanner from "components/UnauthorizedBanner/UnauthorizedBanner";
import { useTranslation } from "react-i18next";
import { getLanguageUrlParam } from "localization/helpers";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

function UserDetailsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { uId } = useParams();
  let userId = null;
  if (uId) {
    userId = decrypt(uId, "CDS:User-List-Details__User-Id", true);
  }

  const languageUrlParam = getLanguageUrlParam();

  const userData = useAppSelector(
    (state) => state.userDetails.companyUsersById[userId]
  );

  const allRoles = useAppSelector((state) => state.userRoles.allRoles);

  const createUserTemporaryProfileStatus = useAppSelector(
    (state) => state.userTemporaryProfile.createUserTemporaryProfileStatus
  );

  const myDetails = useAppSelector((state) => state.userDetails.myDetails);

  const isAdmin = useAppSelector((state) => state.user.isAdmin);

  const adminAddTemporaryUser = (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <ScrollToTop />
      {isAdmin ? (
        <>
          <div className="msds-text-header-2-bold">
            {t("pages.userDetails.title")}
          </div>
          <p className="msds-text-gray-10 msds-text-body-1">
            {myDetails?.isMilestoneEmployee
                ? t("pages.userDetails.descriptionMilestoneEmployee")
                : t("pages.userDetails.description")}
          </p>        
          <div className="mt-8">
              <UserDetailsForm
                userId={userId}
                userData={userData}
                allRoles={allRoles}
              />
          </div>
        </>
        ) : (
          <UnauthorizedBanner />
        )}
    </div>
  );

  const adminAddTemporaryUserSuccess = (
      <div className="col-10 offset-1 col-lg-6 offset-lg-1">
        <ScrollToTop />
        <div className="msds-text-header-2-bold">
          {t("pages.userDetails.titleSuccess")}
        </div>        
        <p className="msds-text-gray-10 msds-text-body-1">
          {t("pages.userDetails.descriptionSuccess.message1")}
        </p>
        <p className="msds-text-gray-10 msds-text-body-1">
          {t("pages.userDetails.descriptionSuccess.bestRegards")}
          <br />
          {t("pages.userDetails.descriptionSuccess.milestoneTeam")}
        </p>
        <div className="user-portal__create-login-form--success">
          <Button
            label={t("buttons.backToList")}
            onClickHandler={() => {
              navigate(`${languageUrlParam}/user-list`);
            }}
            size={ButtonSize.Medium}
            styling={ButtonStyles.Secondary}
          />
        </div>          
      </div>
  );

  useEffect(() => {
    // Below is a function that gets triggered whenever this component is unmounted
    return () => {
      dispatch(resetCreateUserTemporaryProfileStatus());
      dispatch(resetUpdateExistingUserDataStatus());
    };
  }, []);

  return (
    <>
      {createUserTemporaryProfileStatus === Status.Success ? (
        <FormConfirmation content={adminAddTemporaryUserSuccess} />
      ) : (
        adminAddTemporaryUser
      )}
    </>
  );
}

export default UserDetailsPage;
