import { Status } from "lib/enums";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { NavLink, useParams } from "react-router-dom";
import { postUpdatedEmailConfirmationThunk } from "redux/api/userUpdatedEmailConfirmation/actions";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const UserUpdateEmailConfirmationPage = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useAppDispatch();

    const postUpdatedEmailConfirmationtatus = useAppSelector((state) => state.userUpdatedEmailConfirmation.postUpdatedEmailConfirmationtatus);

    const backendErrorMessage = useAppSelector((state) => state.userUpdatedEmailConfirmation.backendErrors.postUpdatedEmailConfirmationErrorMsg);

    const handleActivateAccountClick = () => {
        dispatch(postUpdatedEmailConfirmationThunk(id));
    };

    useEffect(() => {
        handleActivateAccountClick();
    }, []);

    return (
        <div className="col-10 offset-1 col-lg-6 offset-lg-1">            
            <ScrollToTop />
            <div className="msds-text-header-2-bold">{t("pages.userUpdateEmail.title")}</div>            
            {postUpdatedEmailConfirmationtatus === Status.Success && (
                <p className="msds-text-gray-10 msds-text-body-1">
                    <Trans i18nKey={"pages.userUpdateEmail.description"}>
                        Your email has been updated successfully. Please go to
                        <NavLink className="navigation__links" to="/edit-user-details">
                            My Details
                        </NavLink>
                        to see the changes.
                    </Trans>
                </p>
            )}
            {postUpdatedEmailConfirmationtatus === Status.Rejected && (
                <div className="msds-input msds-input--error">
                    <p className="msds-input__info-message">{backendErrorMessage}</p>
                </div>
            )}                       
        </div>        
    );
};

export default UserUpdateEmailConfirmationPage;
