import UserDataForm from "components/EnterUserDataForm/EnterUserDataForm";
import { Trans, useTranslation } from "react-i18next";
import { useAppSelector } from "redux/hooks";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const EnterUserData = () => {
  const { t } = useTranslation();

  const telephoneCodes = useAppSelector(
    (state) => state.configutationLists.CountryTelephoneCodes
  );

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <ScrollToTop />
      <div className="msds-text-header-2-bold">
        {t("pages.signUp.title")}
      </div>      
      <p className="msds-text-gray-10 msds-text-body-1">
        <Trans i18nKey="pages.signUp.alreadyHaveAccount">
          Already have an account?
          <a href="/">Log in here</a>
        </Trans>
      </p>
      <div className="user-portal__description-text msds-text-body-1 msds-text-gray-10">
        {t("pages.signUp.purposeText")}
      </div>
      <UserDataForm telephoneCodes={telephoneCodes} />
    </div>
  );
};

export default EnterUserData;
