import { useTranslation } from "react-i18next";
import { Status } from "lib/enums";
import { CompanyVerificationResponseDataType } from "lib/types";
import "./no-matches-found.scss";
import LoginSendSupportEmailForm from "components/LoginSendSupportEmailForm/LoginSendSupportEmailForm";
import ContactSupportConfirmation from "pages/SignUpPage/SingupSteps/2-NoMatch-ContactSupportConfirmation/ContactSupportConfirmation";
import { useAppSelector, useAppDispatch } from "redux/hooks";

const NoMatchesFound = ({
  userData,
  handleIsMyCompanyOnTheList,
  matchesWereFound,
}: {
  userData: CompanyVerificationResponseDataType;
  handleIsMyCompanyOnTheList: React.Dispatch<React.SetStateAction<boolean>>;
  matchesWereFound: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const companyInformationFields = [
    {
      label: `${t("pages.signUp.noMatch.labels.company")}:`,
      field: "companyName",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.address")}:`,
      field: "companyAddress",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.zipCode")}:`,
      field: "companyPostalCode",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.city")}:`,
      field: "companyCity",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.country")}:`,
      field: "companyCountry",
    },
  ];

  const contactInformationFields = [
    {
      label: `${t("pages.signUp.noMatch.labels.email")}:`,
      field: "contactEmail",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.firstName")}:`,
      field: "contactFirstName",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.lastName")}:`,
      field: "contactLastName",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.telephone")}:`,
      field: "contactPhone",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.contactCountry")}:`,
      field: "contactCountry",
    },
  ];

  const sendEmailStatus = useAppSelector(
    (state) => state.supportEmail.sendSupportEmailStatus
  );
  return (
    <>
      {sendEmailStatus !== Status.Success ? (
        <div className="col-10 offset-1 col-lg-6 offset-lg-1">
            <div className="msds-text-header-2-bold">
              {t("pages.verifyCompany.title")}
            </div>            
            <p className="no-matched-company-page__description">{t("pages.verifyCompany.descriptionNoMatches")}</p>
            <div className="no-matched-company-page__content">
              <div className="no-matched-company-page__data-section">
                <div className="msds-text-header-3-light">
                  {t("pages.signUp.noMatch.companyInformation")}:
                </div>

                {companyInformationFields.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="no-matched-company-page__data-item"
                    >
                      <p className="msds-text-body-2-bold">{data.label}</p>
                      <p className="msds-text-body-1">{userData[data.field]}</p>
                    </div>
                  );
                })}
              </div>
              <div className="no-matched-company-page__data-section">
                <div className="msds-text-header-3-light">
                  {t("pages.signUp.noMatch.contactInformation")}:
                </div>
                {contactInformationFields.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="no-matched-company-page__data-item"
                    >
                      <p key={index} className="msds-text-body-2-bold">
                        {data.label}
                      </p>
                      <span className="msds-text-body-1">
                        {" "}
                        {userData[data.field]}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <p className="mb-0">{t("pages.verifyCompany.message1")}</p>
              <p className="mt-0">{t("pages.verifyCompany.message2")}</p>
              <LoginSendSupportEmailForm
                sendEmailStatus={sendEmailStatus}
                userData={userData}
                goBackButtonHandler={handleIsMyCompanyOnTheList}
                matchesExist={matchesWereFound}
              />
            </div>
          </div>        
      ) : (
        <ContactSupportConfirmation />
      )}
    </>
  );
};

export default NoMatchesFound;
