import Button from "components/Button/Button";
import { MILESTONE_URL } from "config";
import { ButtonSize, ButtonStyles } from "lib/enums";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const VerifyCompanyConfirmation = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const notNowcallbackUrl = searchParams?.get("callback"); 

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <div className="msds-text-header-2-bold">
        { t("pages.verifyCompany.titleSuccess") }
      </div>      
      <p className="msds-text-gray-10 msds-text-body-1">
        { t("pages.verifyCompany.descriptionSuccess") }
      </p>      
      <div className="user-portal__create-login-form--success">
        { notNowcallbackUrl && (
          <Button
          href={notNowcallbackUrl}
          label={t("buttons.continue")}
          size={ButtonSize.Medium}
          styling={ButtonStyles.Secondary}
          />
        )}
        { !notNowcallbackUrl && (
          <Button
            href={`${MILESTONE_URL()}/my-milestone`}
            label={t("buttons.goToLoginPage")}
            size={ButtonSize.Medium}
            styling={ButtonStyles.Secondary}
          />
        )}
      </div>
    </div>
  );
};

export default VerifyCompanyConfirmation;
