import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import i18next from "i18next";

const t = i18next.t;

export const userListColumns = [
    {
        Header: "Id",
        accessor: "id",
    },
    {
        Header: () => t("pages.accountsApproval.table.columnLabels.companyName"),
        accessor: "companyName",
    },
    {
        Header: () => t("pages.accountsApproval.table.columnLabels.firstName"),
        accessor: "firstName",
    },
    {
        Header: () => t("pages.accountsApproval.table.columnLabels.lastName"),
        accessor: "lastName",
    },
    {
        Header: () => t("pages.accountsApproval.table.columnLabels.phone"),
        accessor: "phone",
    },
    {
        Header: () => t("pages.accountsApproval.table.columnLabels.email"),
        accessor: "email",
    },
    {
        Header: () => t("pages.accountsApproval.table.columnLabels.signupDate"),
        accessor: "signupDate",
        sortType: (date1: { values: { signupDate: string } }, date2: { values: { signupDate: string } }) => {
            
          // Gets date1 and date2 date information
          const [datePart1, timePart1] = date1.values.signupDate.split(" ");
          const [day1, month1, year1] = datePart1.split("/").map(Number);
          const [hours1, minutes1] = timePart1.split(':').map(Number);

          const [datePart2, timePart2] = date2.values.signupDate.split(" ");
          const [day2, month2, year2] = datePart2.split("/").map(Number);
          const [hours2, minutes2] = timePart2.split(':').map(Number);

          //Creates dates based on their date information
          const singupDate1 = new Date(year1, month1 - 1, day1, hours1, minutes1);
          const singupDate2 = new Date(year2, month2 - 1, day2, hours2, minutes2);

          //Compare and return the result
          return singupDate1.valueOf() - singupDate2.valueOf();
        },
    },
];
