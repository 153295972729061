import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { resetPasswordThunk } from "../../redux/userActions/actions";
import FormButton from "../FormComponents/FormButton/FormButton";
import { ButtonSize, ButtonStyles, Status } from "lib/enums";
import "./reset-password-form.scss";
import { useTranslation } from "react-i18next";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import { ValidationTypes } from "components/FormComponents/formHooks/useValidation";
import Input from "components/FormComponents/Input/Input";
import Form from "components/FormComponents/Form/Form";

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const resetPasswordStatus = useAppSelector(
    (state) => state.userActions.resetPasswordStatus
  );
  const backendErrorMsg: string = useAppSelector(
    (state) => state.userActions.backendErrors.resetPasswordErrorMsg
  );

  const username = useFormFieldState();

  const requiredFormFields = [username];

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    dispatch(resetPasswordThunk(username.state.value as string));
  };

  return (
    <>
      {resetPasswordStatus === Status.Rejected && (
        <div className="msds-input msds-input--error">
          <p className="msds-input__info-message">
            {backendErrorMsg ?? "Sorry, it seems that an error has occurred."}
          </p>
        </div>
      )}
      <Form
        cssClasses="user-portal__reset-password"
        handleSubmit={handleSubmitForm}
        requiredFields={requiredFormFields}
      >
        <Input
          id="create-login-email-input"
          type="text"
          placeholder={t("forms.labels.username", { required: "*" })}
          label={t("forms.labels.username", { required: "*" })}
          initialMessage={{
            validation:
              "Please provide a valid email or username.",
          }}
          validation={ValidationTypes.HasValue}
          makeInvalid={username.state.makeInvalid}
          handleDataChange={(newValue, isValid) => {
            username.setState({ value: newValue, isValid: isValid });
          }}
        />

        <FormButton
          id="reset-password-form__e2e-reset-password-button"
          label={t("buttons.resetPassword")}
          size={ButtonSize.Large}
          styling={ButtonStyles.Tertiary}
          eventCategory="User Portal"
          eventLabel="Reset password Form"
        />
        {backendErrorMsg !== "" && (
          <div className="reset-password-form__create-login-link">
            <a href="/">{t("pages.resetPassword.errorMessageBottom")}</a>
          </div>
        )}
      </Form>
    </>
  );
};

export default ResetPasswordForm;
