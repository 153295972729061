import { useState, useEffect } from "react";
import Table from "../../../../components/Table/Table";
import { useAppSelector } from "redux/hooks";
import { toLongDate, encrypt } from "lib/helpers";
import UnauthorizedBanner from "components/UnauthorizedBanner/UnauthorizedBanner";
import { useTranslation } from "react-i18next";
import { userListColumns } from "./AccountsApprovalListColumns";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

function AccountsApprovalListPage() {
  const { t } = useTranslation();
  const isInternalUserAdmin = useAppSelector((state) => state.user.isInternalUserAdmin);
  const [initialData, setInitialData] = useState([]);
  
  const accountsApprovalList = useAppSelector(
    (state) => state.userDetails.accountsApprovalListById
  );

  const setTableRowId= (activationCode) => {
    if (activationCode) {
      return encrypt(activationCode, "CDS:Account-Approval-List__Account-Code", true);
    }
    return "";
  };

  useEffect(() => {
    let newTableData = [];
    Object.values(accountsApprovalList).forEach((accountsApproval) => {
      if (isInternalUserAdmin) {
        newTableData.push({
          id: setTableRowId(accountsApproval.activationCode),
          companyName: accountsApproval.companyName,
          firstName: accountsApproval.firstName,
          lastName: accountsApproval.lastName,
          phone: accountsApproval.phone,
          email: accountsApproval.email,
          signupDate: toLongDate(accountsApproval.createdDate)          
        });
      }
    });
    setInitialData(newTableData);
  }, [accountsApprovalList]);

  return (
    <div className="col-10 offset-1">
      <ScrollToTop />
      {isInternalUserAdmin ? (
        <>
          <div className="msds-text-header-2-bold">{t("pages.accountsApproval.title")}</div>
          <p className="msds-text-gray-10 msds-text-body-1">
            {t("pages.accountsApproval.description")}
          </p>      
          {initialData.length > 0 ? (
          <Table
            initialData={initialData}
            rowClickBaseUrl={"/accounts-approval/"}
            tableColumns={userListColumns}
          />
          ) : (             
          <div className="msds-block-padding-small">
            <div className="msds-text-header-2 mb-4">There is no account for approval at the moment.</div>
          </div>            
          ) } 
        </>          
      ) : (
        <UnauthorizedBanner />
      )}
    </div>
  );
}

export default AccountsApprovalListPage;
