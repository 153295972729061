const Form = ({
  cssClasses,
  handleSubmit,
  children,
  requiredFields,
  optionalFormFields
}: FormProps) => {
  function submitForm(e) {
    e.preventDefault();

    const isRequiredFieldsValid = requiredFields?.every(
      (field) => field.state.isValid && field.state.value
    );

    const isOptionalFieldsValid = optionalFormFields
      ? optionalFormFields.every((field) => !field.state.value || field.state.isValid)
      : true;

    if (!isRequiredFieldsValid || !isOptionalFieldsValid) {      
      requiredFields?.forEach((field) => {
        if (!field.state.value || !field.state.isValid) {
          field.setState({
            ...field.state,
            makeInvalid: true,
          });
        }
      });

      optionalFormFields?.forEach((field) => {
        if (field.state.value && !field.state.isValid) {
          field.setState({
            ...field.state,
            makeInvalid: true,
          });
        }
      });
      return;
    }

    handleSubmit(e);
  }

  return (
    <form className={cssClasses && cssClasses} onSubmit={(e) => submitForm(e)}>
      {children}
    </form>
  );
};

type FormProps = {
  cssClasses?: string;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
  requiredFields?: RequiredFieldsType;
  optionalFormFields?: FormFieldsType;
};

export type RequiredFieldsType = {
  state: FormElementState;
  setState: (newState: FormElementState) => void;
}[];

export type FormFieldsType = {
  state: FormElementState;
  setState: (newState: FormElementState) => void;
}[];

export type FormElementState = {
  value: string | string[];
  isValid: boolean;
  makeInvalid?: boolean;
};

export default Form;
