import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormButton from "../FormComponents/FormButton/FormButton";
import { updateMyPasswordThunk } from "redux/api/userDetails/actions";
import { UpdatePasswordType } from "lib/types";
import { resetUpdateMyPasswordStatus } from "redux/api/userDetails/userDetailsSlice";
import { ButtonSize, ButtonStyles, Status } from "lib/enums";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import Form, { RequiredFieldsType } from "components/FormComponents/Form/Form";
import Input from "components/FormComponents/Input/Input";
import { ValidationTypes } from "components/FormComponents/formHooks/useValidation";
import { MILESTONE_URL } from "config";

const MyDetailsResetPasswordForm = () => {
  const dispatch = useAppDispatch();
  const [successUpdateMessage, setSuccessUpdateMessage] = useState("");

  const updateMyPaswordStatus = useAppSelector(
    (state) => state.userDetails.updateMyPasswordStatus
  );

  const updateMyPasswordErrorMsg = useAppSelector(
    (state) => state.userDetails.backendErrorMessages.updateMyPasswordErrorMsg
  );

  const oldPassword = useFormFieldState();
  const newPassword = useFormFieldState();
  const confirmNewPassword = useFormFieldState();

  const requiredFormFields: RequiredFieldsType = [
    oldPassword,
    newPassword,
    confirmNewPassword,
  ];

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    const updatePasswordData: UpdatePasswordType = {
      oldPassword: oldPassword.state.value as string,
      newPassword: newPassword.state.value as string,
      confirmPassword: confirmNewPassword.state.value as string,
    };
    dispatch(updateMyPasswordThunk(updatePasswordData));
  };

  useEffect(() => {
    if (updateMyPaswordStatus === Status.Success) {
      setSuccessUpdateMessage(
        t("pages.myDetails.changePasswordSection.successMessage")
      );
    }
  }, [updateMyPaswordStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateMyPasswordStatus());
    };
  }, [dispatch]);

  return (
    <>
      <h2 className="msds-text-header-2 mt-0 mb-6">
        {t("pages.myDetails.changePasswordSection.title")}
      </h2>
      <Form
        cssClasses="user-portal__edit-user-details-reset-password-form"
        handleSubmit={handleSubmitForm}
        requiredFields={requiredFormFields}
      >
        <Input
          id="my-details-reset-password-form__e2e-old-password-input"
          type="password"
          placeholder={t("forms.labels.oldPassword", { required: "*" })}
          label={t("forms.labels.oldPassword", { required: "*" })}
          initialMessage={{ validation: "Please enter your old password." }}
          validation={ValidationTypes.HasValue}
          makeInvalid={oldPassword.state.makeInvalid}
          handleDataChange={(newValue, isValid) => {
            oldPassword.setState({ value: newValue, isValid: isValid });
          }}
        />
        <Input
          id="my-details-reset-password-form__e2e-new-password-input"
          type="password"
          placeholder={t("forms.labels.newPassword", { required: "*" })}
          label={t("forms.labels.newPassword", { required: "*" })}
          initialMessage={{
            validation:
              "Please enter a password. Password must be 8 characters or more, contain upper- and lowercase letters, at least 1 number and 1 special character",
          }}
          validation={ValidationTypes.IsPassword}
          makeInvalid={newPassword.state.makeInvalid}
          handleDataChange={(newValue, isValid) => {
            newPassword.setState({ value: newValue, isValid: isValid });
          }}
        />
        <Input
          id="my-details-reset-password-form__e2e-confirm-new-password-input"
          type="password"
          placeholder={t("forms.labels.confirmNewPassword", { required: "*" })}
          label={t("forms.labels.confirmNewPassword", { required: "*" })}
          initialMessage={{ validation: "Password does not match." }}
          validation={ValidationTypes.IsConfirmPassword}
          makeInvalid={confirmNewPassword.state.makeInvalid}
          data={{ password: newPassword.state.value }}
          handleDataChange={(newValue, isValid) => {
            confirmNewPassword.setState({ value: newValue, isValid: isValid });
          }}
        />
        <p className="msds-text-gray-10 msds-text-body-1">
          <Trans
            components={{
              anchor: <a href={`${MILESTONE_URL()}/privacy-policy/`} />,
            }}
          >
            {t("forms.privacyNoticeRich")}
          </Trans>
        </p>
        <p className="msds-text-gray-10 msds-text-body-1">{t("forms.mandatoryFieldsInformation")}</p>
        {updateMyPasswordErrorMsg && (
          <div className="msds-text-danger-red-1">
            {updateMyPasswordErrorMsg}
          </div>
        )}
        {successUpdateMessage && (
          <div className="msds-text-confirm-green">{successUpdateMessage}</div>
        )}
        <FormButton
          id="my-details-reset-password-form__e2e-save-button"
          label={t("buttons.save")}
          size={ButtonSize.Large}
          styling={ButtonStyles.Secondary}
        />
      </Form>
    </>
  );
};
export default MyDetailsResetPasswordForm;
