import "@milestone-sys/web-design-system/main.css";
import "./footer.scss";
import { MILESTONE_URL } from "config";
import { useTranslation } from "react-i18next";

function NavigationFooter() {    
    const milestoneUrl = MILESTONE_URL();
    const aboutUsLink = milestoneUrl + "/company/about-milestone/about-us/";
    const contactUsLink = milestoneUrl + "/support/contact-us/contact-us/";
    const officesLink = milestoneUrl + "/company/about-milestone/global-offices/";
    const careersLink = milestoneUrl + "/company/career/working-in-milestone/";
    const signUpForNewsLink = "https://pardot.milestonesys.com/marketing-communication";
    const shareYourFeedbackLink = "https://pardot.milestonesys.com/l/53942/2022-01-03/l3mbx7";
    const termsOfUseLink = milestoneUrl + "/terms-of-use/";
    const privacyPolicyLink = milestoneUrl + "/privacy-policy/";
    const cookiePolicyLink = milestoneUrl + "/cookie-policy-milestone-systems/";
    const mapPolicyLink = milestoneUrl + "/minimum-advertised-price-policy/";
    const makeAWhistleblowerReportLink = milestoneUrl + "/company/about-milestone/csr/whistleblower/";
    const modernSlaveryActLink = milestoneUrl + "/company/about-milestone/csr/modern-slavery-act/";    

    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();

    return (
        <div className="footer-column col-12" id="FooterSection">
            <div className="container">
                <div className="row">                    
                    <div className="col-12 col-sm-8 mb-6">
                        <div className="row">
                            <div className="col-12 col-xs-12">
                                <div className="footer-column__links-list footer-column__links-list--with-heading">
                                    <div className="footer-column__links-list-item">
                                        <span className="footer-column__links-list-item-link">{t("navigation.footerNavigation.company")}</span>
                                    </div>
                                </div>
                            </div>
                    
                            <div className="col-6 mb-6">
                                <ul className="footer-column__links-list mt-0">
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={aboutUsLink}>{t("navigation.footerNavigation.aboutUs")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={contactUsLink}>{t("navigation.footerNavigation.contactUs")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={officesLink}>{t("navigation.footerNavigation.offices")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={careersLink}>{t("navigation.footerNavigation.careers")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                        <a  className="footer-column__links-list-item-link" 
                                                href={signUpForNewsLink}
                                                target="_blank"
                                                rel="noopener noreferrer">{t("navigation.footerNavigation.signUpForNews")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                            <a  className="footer-column__links-list-item-link" 
                                                href={shareYourFeedbackLink}
                                                target="_blank"
                                                rel="noopener noreferrer">{t("navigation.footerNavigation.shareYourFeedback")}</a>
                                        </li>
                                </ul>
                            </div>
                                    
                            <div className="col-6 mb-6">
                                <ul className="footer-column__links-list mt-0">
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={termsOfUseLink}>{t("navigation.footerNavigation.termsOfUse")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={privacyPolicyLink}>{t("navigation.footerNavigation.privacyPolicy")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={cookiePolicyLink}>{t("navigation.footerNavigation.cookiePolicy")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={mapPolicyLink}>{t("navigation.footerNavigation.mapPolicy")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={makeAWhistleblowerReportLink}>{t("navigation.footerNavigation.makeAWhistleblowerReport")}</a>
                                        </li>
                                        <li className="footer-column__links-list-item">
                                            <a className="footer-column__links-list-item-link" href={modernSlaveryActLink}>{t("navigation.footerNavigation.modernSlaveryAct")}</a>
                                        </li>
                                </ul>
                            </div>
                        </div>            
                    </div>                
                    <div className="col-6 col-sm-4 mb-6">            
                        <div className="footer-column__milestone-logo"></div>
                        <p className="footer-column__text">
                            {t("navigation.footerNavigation.compliance")}
                            <br />
                            <br />
                            {t("navigation.footerNavigation.copyright", { year: currentYear })}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );    
}

export default NavigationFooter;