import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import Button from "components/Button/Button";
import { ButtonSize, ButtonStyles } from "lib/enums";
import { MILESTONE_URL } from "config";

const SignUpConfirmation = () => {
  const { t } = useTranslation();

  const companyAccountDetected = useAppSelector(
    (state) => state.signup.companyAccountDetected
  );

  const description = companyAccountDetected ? (
    <>
      <p className="msds-text-gray-10 msds-text-body-1">
        {t("pages.signUp.confirmation.descriptionCompanydetected.message1", {
          company: companyAccountDetected,
        })}
      </p>
      <p className="msds-text-gray-10 msds-text-body-1">
        {t("pages.signUp.confirmation.descriptionCompanydetected.message2")}
      </p>
    </>
  ) : (
    <>
      <p className="msds-text-gray-10 msds-text-body-1">
        {t("pages.signUp.confirmation.descriptionNoCompanyDetected.message1")}
      </p>
      <p className="msds-text-gray-10 msds-text-body-1">
        {t("pages.signUp.confirmation.descriptionNoCompanyDetected.message2")}
      </p>
    </>
  );

  const header = companyAccountDetected
    ? t("pages.signUp.confirmation.titleCompanyDetected", {
        company: companyAccountDetected,
      })
    : t("pages.signUp.confirmation.titleNoCompanyDetected");

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <div className="user-portal__create-login-form--success">
        <div className="msds-text-header-2-bold">
          {header}
        </div>        
        {description}
        <Button
          href={
            companyAccountDetected
              ? `${MILESTONE_URL()}`
              : `${MILESTONE_URL()}/my-milestone/`
          }
          label={
            companyAccountDetected
              ? t("buttons.goToFrontPage")
              : t("buttons.goToLoginPage")
          }
          size={ButtonSize.Medium}
          styling={ButtonStyles.Secondary}
        />
      </div>       
    </div>
  );
};

export default SignUpConfirmation;
