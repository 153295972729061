import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form, { FormFieldsType } from "components/FormComponents/Form/Form";
import Input from "components/FormComponents/Input/Input";
import Select from "components/FormComponents/Select/Select";
import FormButton from "components/FormComponents/FormButton/FormButton";
import { CAPTCHA_SITE_KEY } from "config";
import { postSignupCompanyVerificationThunk } from "redux/signup/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ButtonSize, ButtonStyles } from "lib/enums";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import { RequiredFieldsType } from "components/FormComponents/Form/Form";
import { ValidationTypes } from "components/FormComponents/formHooks/useValidation";
import "./signup-form.scss";
import { SignupPopoverDescription } from "./SignupPopoverDescription";
import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";

const EnterCompanyDataForm = ({ regioncountries }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const savedCompanyData = useAppSelector(
    (state) => state.signup.enteredCompanyData
  );

  let countryNames = [];
  if (regioncountries) {
    countryNames = regioncountries.map(countryElement => ({
      label: countryElement.label,
      value: countryElement.label
    }));
  }

  const managementId = useFormFieldState();
  const company = useFormFieldState(savedCompanyData?.company);
  const zip = useFormFieldState(savedCompanyData?.zip);
  const country = useFormFieldState(savedCompanyData?.countryKeySelected);
  const companyNumber = useFormFieldState(savedCompanyData?.companyNumber);
  const email = useFormFieldState(savedCompanyData?.email);

  const requiredFormFields: RequiredFieldsType = [company, country];
  const optionalFormFields: FormFieldsType = [companyNumber,email]
  const backendErrorMessage = useAppSelector(
    (state) => state.signup.backendErrors.verifyCompanyBackendErrorMessage
  );

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    const captcha = (
      document.querySelector(".g-recaptcha-response") as HTMLInputElement
    )?.value;

    dispatch(
      postSignupCompanyVerificationThunk({
        managementId: managementId.state.value,
        company: company.state.value,
        zip: zip.state.value,
        countryKeySelected: country.state.value,
        companyNumber: companyNumber.state.value,
        email: email.state.value,
        captcha: captcha,
      })
    );
  };

  useEffect(() => {
    // Create required script element with logic from google so that our recaptcha implementation in the form works
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="msds-input msds-input--error">
        <p className="msds-input__info-message">{backendErrorMessage}</p>
      </div>
      <Form
        cssClasses="user-portal__signup-form"
        handleSubmit={handleSubmitForm}
        requiredFields={requiredFormFields}
        optionalFormFields={optionalFormFields}
      >
        <Input
          id="create-login-management-id-input"
          type="hidden"
          placeholder="Management Id*"
          label="Management id"
          handleDataChange={(newValue, isValid) =>
            managementId.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <Input
          id="create-login-company-input"
          placeholder={t("forms.labels.company", { required: "*" })}
          label={t("forms.labels.company", { required: "*" })}
          initialValue={savedCompanyData?.company}
          initialMessage={{
            validation: "Please enter your company name.",
          }}
          validation={ValidationTypes.HasValue}
          makeInvalid={company.state.makeInvalid}
          maxLength={240}
          handleDataChange={(newValue, isValid) => {
            company.setState({ value: newValue, isValid: isValid });
          }}
        />
        <div className="msds-popover field-description-container">
          <div className="question-icon-container">
            <svg className="msds-select-input__question">
              <use href={`${spritemap}#question`} />
            </svg>
          </div>

          <div className="msds-text-body-1 msds-text-gray-10 field-definition-text">
            {t("pages.signUp.companyDescription.whatIsCompany")}
          </div>

          <SignupPopoverDescription
            popoverTitle={t("pages.signUp.companyDescription.title")}
            popoverParagraph={t(
              "pages.signUp.companyDescription.definition.definition1"
            )}
            popoverParagraph2={t(
              "pages.signUp.companyDescription.definition.definition2"
            )}
            popoverParagraph3={t(
              "pages.signUp.companyDescription.definition.definition3"
            )}
          />
        </div>

        <Input
          id="create-login-zip-input"
          placeholder={t("forms.labels.zipCode", { required: "" })}
          label={t("forms.labels.zipCode", { required: "" })}
          initialValue={savedCompanyData?.zip}
          maxLength={10}
          handleDataChange={(newValue, isValid) => {
            zip.setState({ value: newValue, isValid: isValid });
          }}
        />
        <Select
          id="create-login-country-selection"
          options={regioncountries ?? []}
          defaultOption={{ label: "Country or Region *", value: "" }}
          initialValue={savedCompanyData?.countryKeySelected}
          initialMessage={{ validation: "Please select a country." }}
          validation={ValidationTypes.HasValue}
          makeInvalid={country.state.makeInvalid}
          handleDataChange={(newValue, isValid) =>
            country.setState({ value: newValue, isValid: isValid })
          }
        />
        <Input
          id="create-login-company-number-input"
          placeholder={t("forms.labels.companyNumber", {
            required: "",
          })}
          label={t("forms.labels.companyNumber", { required: "" })}
          initialMessage={{
            validation: "Please enter a valid D-U-N-S number.",
          }}
          initialValue={savedCompanyData?.companyNumber}
          validation={ValidationTypes.IsDunsNumber}
          handleDataChange={(newValue, isValid) =>
            companyNumber.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <div className="msds-popover field-description-container">
          <div className="question-icon-container">
            <svg className="msds-select-input__question">
              <use href={`${spritemap}#question`} />
            </svg>
          </div>
          <div className="msds-text-body-1 msds-text-gray-10 field-definition-text">
            {t("pages.signUp.DUNSDescription.whatIsDUNS")}
          </div>
          <SignupPopoverDescription
            popoverTitle={t("pages.signUp.DUNSDescription.title")}
            popoverParagraph={t(
              "pages.signUp.DUNSDescription.definition.definition1"
            )}
            popoverParagraph2={t(
              "pages.signUp.DUNSDescription.definition.definition2"
            )}
            popoverParagraph3={t(
              "pages.signUp.DUNSDescription.definition.definition3"
            )}
          />
        </div>
        <Input
          id="create-login-company-email-input"
          placeholder={t("forms.labels.companyEmail", {
            required: "",
          })}
          label={t("forms.labels.companyEmail", { required: "" })}
          initialMessage={{
            validation: "Please enter your company email address",
          }}
          initialValue={savedCompanyData?.email}
          validation={ValidationTypes.IsStrictEmail}
          maxLength={80}
          handleDataChange={(newValue, isValid) =>
            email.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <div className="field-description-container">
          <div className="msds-text-body-1 msds-text-gray-10 email-text">
            {t("pages.signUp.companyEmailGuide")}
          </div>
        </div>

        <div className="g-recaptcha" data-sitekey={CAPTCHA_SITE_KEY}></div>
        <FormButton
          id="create-login-submit-button"
          label={t("buttons.next")}
          size={ButtonSize.Large}
          styling={ButtonStyles.Tertiary}
        />
      </Form>
    </>
  );
};

export default EnterCompanyDataForm;
