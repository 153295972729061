import CreateNewPasswordForm from "components/CreateNewPasswordForm/CreateNewPasswordForm";
import FormConfirmation from "components/FormConfirmation/FormConfirmation";
import Button from "components/Button/Button";
import { ButtonStyles, ButtonSize } from "../../lib/enums";
import { useAppSelector } from "redux/hooks";
import { Status } from "lib/enums";
import { useTranslation } from "react-i18next";
import { MILESTONE_URL } from "config";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const CreatePasswordPage = () => {
  const { t } = useTranslation();
  const postCreatePasswordStatus = useAppSelector(
    (state) => state.userActions.postCreateNewPasswordStatus
  );

  const createNewPasswordConfirmation = (
    <div className="user-portal__create-new-password-form-success">
      <Button
        href={`${MILESTONE_URL()}/my-milestone/`}
        label={t("buttons.goToLoginPage")}
        size={ButtonSize.Medium}
        styling={ButtonStyles.Secondary}
      />
    </div>
  );

  const header =
    postCreatePasswordStatus === Status.Success
      ? t("pages.createNewPassword.titleSuccess")
      : t("pages.createNewPassword.title");

  const createNewPasswordFormDataLayer = {
    dataLayer: {
      event: "Form_submission",
      eventCategory: "User Portal",
      eventLabel: "Create New Password Form",
    },
  };

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <ScrollToTop />
      <div className="msds-text-header-2-bold">{header}</div>      
      {postCreatePasswordStatus === Status.Success ? (
        <FormConfirmation
          gtmDataLayer={createNewPasswordFormDataLayer}
          content={createNewPasswordConfirmation}
        />
      ) : (
        <CreateNewPasswordForm />
      )}
    </div>
  );
};

export default CreatePasswordPage;
