const Device = function () {
    function isXSmall() {
        return document.querySelector('body').clientWidth < 576;
    }

    function isSmall() {
        return document.querySelector('body').clientWidth >= 576 && document.querySelector('body').clientWidth < 768;
    }

    function isMedium() {
        return document.querySelector('body').clientWidth >= 768 && document.querySelector('body').clientWidth < 992;
    }

    function isLarge() {
        return document.querySelector('body').clientWidth >= 992 && document.querySelector('body').clientWidth < 1200;
    }

    function isXLarge() {
        return document.querySelector('body').clientWidth >= 1200 && document.querySelector('body').clientWidth < 1400;
    }

    function isXXLarge() {
        return document.querySelector('body').clientWidth >= 1400;
    }

    function isDesktop() {        
        //checks if the page has no vertical scroll bar
        if (window.innerWidth == window.innerWidth - document.documentElement.clientWidth) {
            return document.body.clientWidth >= 1200;
        }

        return document.body.clientWidth >= 1183; //corresponds to Bootstrap xl breakpoint - scrollbar width (17px)
    }

    function isAndroid() {
        return navigator.userAgent.toLowerCase().indexOf("android") > -1;
    }

    function isCurrentClientWidth() {
        return document.body.clientWidth;
    }

    return {
        isXSmall: isXSmall,
        isSmall: isSmall,
        isMedium: isMedium,
        isLarge: isLarge,
        isXLarge: isXLarge,
        isXXLarge: isXXLarge,
        isDesktop: isDesktop,
        isAndroid: isAndroid,
        isCurrentClientWidth: isCurrentClientWidth
    }
}

export default Device;
