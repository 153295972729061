import classnames from "classnames/bind";
import { useEffect, useState } from "react";
import { useValidation, ValidationTypes } from "../formHooks/useValidation";

const Input = ({
  id,
  type,
  placeholder,
  label,
  initialValue,
  initialMessage,
  isDisabled,
  maxLength,
  validation,
  makeInvalid,
  data,
  onEventType = "onBlur",
  handleDataChange,
}: InputProps) => {
  const [value, setValue] = useState(initialValue ?? "");
  const { isValid, message } = useValidation(
    value,
    validation,
    initialMessage,
    data
  );

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (onEventType === "onChange") handleDataChange(value, isValid);
  }, [value, isValid]);

  return (
    <div
      className={classnames(
        "msds-input",
        (isValid === false || makeInvalid) && "msds-input--error"
      )}
    >
      <div className="msds-input__icon-wrapper">
        <input
          id={id}
          // Avoid autocomplete of forms if line below is active
          autoComplete="no"
          type={type ?? "text"}
          className="msds-input__text-input"
          placeholder={placeholder}
          value={value}
          disabled={isDisabled}
          maxLength={maxLength}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={(e) => {
            onEventType === "onBlur" && handleDataChange(value, isValid);
          }}
        />
        <label htmlFor="text-input-lg" className="msds-input__label">
          {label}
        </label>
      </div>
      <p className="msds-input__info-message">
        {isValid === true &&
          !makeInvalid &&
          message &&
          message.info &&
          message.info}
        {(isValid === false || makeInvalid) && message && message.validation}
      </p>
    </div>
  );
};

export default Input;

type InputProps = {
  id: string;
  type?: "text" | "email" | "password" | "number" | "hidden";
  placeholder: string;
  label: string;
  initialValue?: string;
  isDisabled?: boolean;
  maxLength?: number;
  initialMessage?: { info?: string; validation: string };
  validation?: ValidationTypes;
  makeInvalid?: boolean;
  data?: { [key: string]: any };
  onEventType?: "onChange" | "onBlur";
  handleDataChange?: (newValue: any, isValid?: boolean) => void;
};
