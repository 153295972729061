import { useAppDispatch } from "redux/hooks";
import { setAuthenticated } from "redux/user/userSlice";
import { setLanguageUrlParamOnLoad } from "localization/helpers";
import { NavigateFunction, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserManager, UserManagerEvents, UserManagerSettings } from "oidc-client";
import { RedirectionType } from "./types";

//authenticationUtilities.tsx
//Utility that main task is to interact with IDP (SSO) and oidc-client library.
//OIDC should not be used outside of this utility in order to:
//  - provide consistent interaction with IDP (SSO),
//  - make sure that only one instance of UserManager is created,
//  - avoid issues caused by calling or redirecting to IDP (SSO) from multiple places in application,
//  - make debugging easier,
//  - make implementing new functionalities easier,

let identityManager: UserManager = null

//Custom hook that encapsulates all of the actions needed to be run after successful IDP authentication or if session is still active in the broswer storage.
export const useAuthentication = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return async (config: UserManagerSettings, withOriginPageRedirection?: boolean) => {
        const identityManager = getIdentityProvider(config)
        const user = await identityManager.getUser()

        if (user && !user.expired) {
            axios.defaults.headers.common = {
                Authorization: `Bearer ${user.access_token}`
            };
            identityManager.startSilentRenew()
            dispatch(setAuthenticated(true));

            if (withOriginPageRedirection) {
                redirectToOriginPage(navigate)
            }
        }
        else  {
            dispatch(setAuthenticated(false));
        }
    }
}

//Function that encapsulates all possible redirections to IDP (SSO).
export const redirectToIDP = async (config: UserManagerSettings, redirectionType: RedirectionType, saveOriginPagePath?: boolean) => {
    const identityManager = getIdentityProvider(config)

    if (saveOriginPagePath) {
        window.localStorage.setItem("originPagePath", window.location.pathname);
    }

    switch (redirectionType) {
        case RedirectionType.Signin: 
            await identityManager.signinRedirect(); 
            break;

        case RedirectionType.Signout: 
            const user = await identityManager.getUser();
            if (user && user.id_token) {
                await identityManager.signoutRedirect({
                    id_token_hint: user.id_token,
                    post_logout_redirect_uri: config.post_logout_redirect_uri
                });
            } else {
                await identityManager.signoutRedirect();
            }
            identityManager.removeUser(); 
            break;

        case RedirectionType.SigninCallback: 
            await identityManager.signinRedirectCallback(); 
            break;
    }
}

export const addUserLoadedEvent = (config: UserManagerSettings, callback: UserManagerEvents.UserLoadedCallback) => {
    const identityManager = getIdentityProvider(config)
    identityManager.events.addUserLoaded(callback)
}

const redirectToOriginPage = (navigate: NavigateFunction) => {
    const originPagePath = window.localStorage.getItem("originPagePath");
    window.localStorage.setItem("originPagePath", "");

    if (originPagePath) {
        setLanguageUrlParamOnLoad(originPagePath);
    }

    navigate(originPagePath ? originPagePath : "/", { replace: true })
}


const getIdentityProvider = (config: UserManagerSettings) => {
    if (identityManager) {
        return identityManager
    }

    identityManager = new UserManager(config)
    return identityManager
}