import { Outlet } from "react-router-dom";

export const Layout = () => {

  return (   
    <div className="user-portal msds-bg-gray-1">
      {/* An <Outlet> renders whatever child route is currently active,
      so you can think about this <Outlet> as a placeholder for
      the child routes we defined in Routing.tsx. */}
      <div className="container-fluid msds-block-padding-medium">
        <div className="row">          
          <Outlet />          
        </div>
      </div>
    </div>         
  );
};

export default Layout;
