import Button from "components/Button/Button";
import FormButton from "components/FormComponents/FormButton/FormButton";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import { ValidationTypes } from "components/FormComponents/formHooks/useValidation";
import Input from "components/FormComponents/Input/Input";
import Select from "components/FormComponents/Select/Select";
import TextArea from "components/FormComponents/TextArea/TextArea";
import Form from "components/FormComponents/Form/Form";
import { ButtonStyles, SignupSteps, Status } from "lib/enums";
import { SupportEmailType } from "lib/types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { sendSupportEmailThunk } from "redux/api/supportEmail/supportEmailSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { goToStep } from "redux/signup/signupSlice";

const SignupSendSupportEmailForm = ({ telephoneCodes, regioncountries }) => { 
  
  const savedCompanyData = useAppSelector(
    (state) => state.signup.enteredCompanyData
  );

  let countryNames = [];
  if (regioncountries) {
    countryNames = regioncountries.map(countryElement => ({
      label: countryElement.label,
      value: countryElement.label
    }));
  }
  
  let preselectedCountry = regioncountries.find(item => item.value === savedCompanyData?.countryKeySelected);
  const updatedpreselectedCountry = preselectedCountry.label;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const zip = useFormFieldState(savedCompanyData?.zip);
  const country = useFormFieldState(updatedpreselectedCountry);
  const email = useFormFieldState();
  const firstName = useFormFieldState();
  const lastName = useFormFieldState();
  const telephoneCode = useFormFieldState();
  const telephone = useFormFieldState();
  const address = useFormFieldState();
  const city = useFormFieldState();
  const userMessage = useFormFieldState();
  const requiredFormFields = [
    email,
    country,
    firstName,
    lastName,
    telephoneCode,
    telephone,
    address,
    city,
  ];

  const enteredCompanyData = useAppSelector(
    (state) => state.signup.enteredCompanyData
  );

  const backendErrorMessage = useAppSelector(
    (state) => state.supportEmail.sendSupportEmailBackendErrorMsg
  );

  const sendEmailStatus = useAppSelector(
    (state) => state.supportEmail.sendSupportEmailStatus
  );

  const sendEmail = () => {
    const emailData: SupportEmailType = {
      companyName: enteredCompanyData.company,
      contactEmail: email.state.value as string,
      contactFirstName: firstName.state.value as string,
      contactLastName: lastName.state.value as string,
      message: userMessage.state.value as string,
      country: country.state.value as string,
      telephone: telephone.state.value as string,
      telephoneKey: telephoneCode.state.value as string,
      address: address.state.value as string,
      zipCode: zip.state.value as string,
      city: city.state.value as string,
      source: "Customer License Registration"      
    };
    dispatch(sendSupportEmailThunk(emailData));
  };

  useEffect(() => {
    if (sendEmailStatus === Status.Success)
      dispatch(goToStep(SignupSteps.ContactSupportConfirmation));
  }, [sendEmailStatus]);

  return (
    <Form handleSubmit={sendEmail} requiredFields={requiredFormFields}>
      <Input
        id="signup-contact-support-email-input"
        type="email"
        placeholder={t("forms.labels.email", { required: "*" })}
        label={t("forms.labels.email", { required: "*" })}
        initialMessage={{
          validation:
            "Please provide a valid email address from your business or organization.",
        }}
        validation={ValidationTypes.IsStrictEmail}
        makeInvalid={email.state.makeInvalid}
        maxLength={80}
        handleDataChange={(newValue, isValid) =>
          email.setState({ value: newValue, isValid: isValid })
        }
      />
      <Input
        id="signup-contact-support-first-name-input"
        type="text"
        placeholder={t("forms.labels.firstName", { required: "*" })}
        label={t("forms.labels.firstName", { required: "*" })}
        initialMessage={{ validation: "Please enter your first name." }}
        validation={ValidationTypes.HasValue}
        makeInvalid={firstName.state.makeInvalid}
        maxLength={40}
        handleDataChange={(newValue, isValid) =>
          firstName.setState({ value: newValue, isValid: isValid })
        }
      />
      <Input
        id="signup-contact-support-last-name-input"
        type="text"
        placeholder={t("forms.labels.lastName", { required: "*" })}
        label={t("forms.labels.lastName", { required: "*" })}
        initialMessage={{ validation: "Please enter your last name." }}
        validation={ValidationTypes.HasValue}
        makeInvalid={lastName.state.makeInvalid}
        maxLength={80}
        handleDataChange={(newValue, isValid) =>
          lastName.setState({ value: newValue, isValid: isValid })
        }
      />
      <Select
          id="create-login-country-selection"
          options={countryNames ?? []}
          defaultOption={{ label: "Country or Region *", value: "" }}
          initialValue={updatedpreselectedCountry}
          initialMessage={{ validation: "Please select a country." }}
          validation={ValidationTypes.HasValue}
          makeInvalid={country.state.makeInvalid}
          handleDataChange={(newValue, isValid) =>
              country.setState({ value: newValue, isValid: isValid })
          }
      />
      <div className="msds-telephone-field">
        <div className="col-6 col-md-5">
          <Select
            id="signup-contact-support-telephone-code-selection"
            options={telephoneCodes ?? []}
            defaultOption={{ label: "Code *", value: "" }}
            initialMessage={{
              validation: "Please select a country code.",
            }}
            validation={ValidationTypes.HasValue}
            makeInvalid={telephoneCode.state.makeInvalid}
            handleDataChange={(newValue, isValid) =>
              telephoneCode.setState({
                value: newValue,
                isValid: isValid,
              })
            }
          />
        </div>
        <div className="col-6 col-md-7">
          <Input
            id="signup-contact-support-telephone-input"
            type="text"
            placeholder={t("forms.labels.telephone", { required: "*" })}
            label={t("forms.labels.telephone", { required: "*" })}
            initialMessage={{
              validation: "Please enter a valid telephone number.",
            }}
            validation={ValidationTypes.IsNumber}
            makeInvalid={telephone.state.makeInvalid}
            maxLength={35}
            handleDataChange={(newValue, isValid) =>
              telephone.setState({ value: newValue, isValid: isValid })
            }
          />
        </div>
      </div>
      <p className="msds-text-gray-10 msds-text-body-1">{t("pages.signUp.noMatch.message2")}</p>
      <Input
        id="signup-contact-support-address-input"
        type="text"
        placeholder={t("forms.labels.address", { required: "*" })}
        label={t("forms.labels.address", { required: "*" })}
        initialMessage={{ validation: "Please enter the company's address." }}
        validation={ValidationTypes.HasValue}
        makeInvalid={address.state.makeInvalid}
        maxLength={255}
        handleDataChange={(newValue, isValid) =>
          address.setState({ value: newValue, isValid: isValid })
        }
      />
      <Input
        id="create-login-zip-input"
        placeholder={t("forms.labels.zipCode", { required: "" })}
        label={t("forms.labels.zipCode", { required: "" })}
        initialValue={savedCompanyData?.zip}
        initialMessage={{
            validation: "Please enter your zip/postal code.",
        }}
        validation={ValidationTypes.HasValue}
        makeInvalid={zip.state.makeInvalid}
        maxLength={20}
        handleDataChange={(newValue, isValid) => {
            zip.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Input
        id="signup-contact-support-city-input"
        type="text"
        placeholder={t("forms.labels.city", { required: "*" })}
        label={t("forms.labels.city", { required: "*" })}
        initialMessage={{ validation: "Please enter the company's city/town." }}
        validation={ValidationTypes.HasValue}
        makeInvalid={city.state.makeInvalid}
        maxLength={40}
        handleDataChange={(newValue, isValid) =>
          city.setState({ value: newValue, isValid: isValid })
        }
      />
      <TextArea
        id="signup-contact-support-user-message"
        label={t("pages.signUp.noMatch.labels.supportMessage")}
        placeholder={t("pages.signUp.noMatch.labels.supportMessage")}
        maxLength={500}
        handleChangeData={(newValue, isValid) =>
          userMessage.setState({ value: newValue, isValid: isValid })
        }
      />
      {backendErrorMessage && (
        <p className="msds-text-danger-red-1">{backendErrorMessage}</p>
      )}
      <section className="user-information-page__actions">
        <Button
          label={t("buttons.back")}
          styling={ButtonStyles.Secondary}
          onClickHandler={() => {
            dispatch(goToStep(SignupSteps.EnterCompanyData));
          }}
        />
        <FormButton
          id="signup-contact-support-submit-button"
          label={t("buttons.sendToMilestone")}
          styling={ButtonStyles.Tertiary}
        />
      </section>
    </Form>
  );
};

export default SignupSendSupportEmailForm;
