import BurgerMenu from './burgerMenu';
import Device from './device';

const HeaderSectionBlock = function () {

    let device;
    let bodyElement;
    let navigation;
    let navigationMenus;
    let burgerMenu;
    let logInMenu;
    let currentOpenedComponent;

    function initialize() {
        setTimeout(() => {
            if (!document.querySelector("#HeaderSection .header-section-block__container"))
                return;

            //initialize global variables
            cacheDom();

            //Initialize all header components
            burgerMenu.initialize();


            //Initialize events
            bindEvents();

            //Set the header UI
            updateHeaderLayout();
        }, 500);
    }

    function cacheDom() {
        bodyElement = document.querySelector('body');
        navigation = document.querySelector('.header-section-block__navigation');

        if (navigation) {
            navigationMenus = navigation.querySelector('.header-section-block__navigation-menus');
        }
        device = new Device();
        burgerMenu = new BurgerMenu();

    }

    function bindEvents() {
        document.addEventListener("click", function (event) {
            onWindowClick(event);
        }, false);
        window.addEventListener("resize", function () {
            onWindowResize();
        }, false);
    }

    function updateHeaderLayout() {
        if (device.isDesktop()) {
            burgerMenu.unbindBurgerMenuClick();
        }
        else {
            burgerMenu.bindBurgerMenuClick();
        }
    }

    function resetDesktopNavigationState() {
        navigationMenus.querySelectorAll('.header-section-block__navigation-menu').forEach(function (element) {
            element.classList.remove('header-section-block__navigation-menu--active')
        });
    }

    function onWindowClick(event) {
        if (device.isDesktop()) {
            onOutsideDesktopUIClick(event);
        }
        else {
            onOutsideMobileUIClick(event);
        }
    }

    function onOutsideDesktopUIClick(event) {

        if (event) {
            checkOpenedComponent();
        }
    }

    function onOutsideDesktopNavigationClick(event) {

        if (event) {
            //Check if the Mega Menu is open and if the user clicked outside it.
            if (event.target.closest(".header-section-block__navigation")) {
                return false;
            }
        }

        return true;
    }

    function onOutsideMobileUIClick(event) {

        if (event) {

            //check current opened component
            checkOpenedComponent();

            //Check if Burger Menu is opened if so the close it and reset the page scrolling
            if (burgerMenu.isOpened()) {
                if (!checkMultipleComponentsAreOpened() && burgerMenu.onOutsideBurgerMenuClick(event)) {
                    burgerMenu.closeBurgerMenu();
                    resetPageScrolling();
                }
                return;
            }
        }
    }

    function checkOpenedComponent() {
        if ((burgerMenu.isOpened() && currentOpenedComponent == null) ||
            (burgerMenu.isOpened() && currentOpenedComponent != burgerMenu)) {
            updateUIForBurgerMenuComponentOpened();
        }
    }

    function updateUIForBurgerMenuComponentOpened() {
        currentOpenedComponent = burgerMenu;
    }

    function checkMultipleComponentsAreOpened() {
        let counter = 0;
        if (burgerMenu.isOpened()) {
            counter++;
        }
        if (counter > 1) {
            return true;
        }

        return false;
    }

    function resetPageScrolling() {
        if (!device.isDesktop() || (device.isDesktop() && bodyElement.classList.contains('no-scroll'))) {
            bodyElement.classList.remove('no-scroll');
        }
    }

    function onWindowResize() {
        if (device.isDesktop()) {
            resetDesktopNavigationState();
        }
        else {
            burgerMenu.onBurgerMenuWindowResize();
            if (bodyElement.classList.contains('no-scroll')) {
                bodyElement.classList.remove('no-scroll');
            }
        }

        updateHeaderLayout();
    }

    return {
        initialize: initialize
    }
}

const headerSectionBlock = new HeaderSectionBlock();

//Listen for the event
window.addEventListener("externalnavigationload", function (evt) {
    headerSectionBlock.initialize();
});

//Wait for DOM to load, before initializing the header section block
window.addEventListener('load', function () {
    headerSectionBlock.initialize()
}, false);