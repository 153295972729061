import Button from "components/Button/Button";
import UserTemporaryProfileForm from "components/UserTemporaryProfileForm/UserTemporaryProfileForm";
import FormConfirmation from "components/FormConfirmation/FormConfirmation";
import { ButtonSize, ButtonStyles, Status } from "lib/enums";
import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import { MILESTONE_URL } from "config";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const UserTemporaryProfilePage = () => {
  const { t } = useTranslation();
  const postUserTemporaryProfileStatus = useAppSelector(
    (state) => state.userTemporaryProfile.postUserTemporaryProfileStatus
  );

  const compeleTemporaryAccountSuccess = (
    <div className="user-portal__user-temporary-profile-form-success">
      <Button
        href={`${MILESTONE_URL()}/my-milestone/`}
        label={t("buttons.goToLoginPage")}
        size={ButtonSize.Medium}
        styling={ButtonStyles.Secondary}
      />
    </div>
  );

  const header =
    postUserTemporaryProfileStatus === Status.Success
      ? t("pages.userTemporaryProfile.titleSuccess")
      : t("pages.userTemporaryProfile.title");

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <ScrollToTop />
      <div className="msds-text-header-2-bold">{header}</div>      
      {postUserTemporaryProfileStatus === Status.Success ? (
        <FormConfirmation content={compeleTemporaryAccountSuccess} />
      ) : (
        <UserTemporaryProfileForm />
      )}
    </div>
  );
};

export default UserTemporaryProfilePage;
