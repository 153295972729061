import Table from "components/Table/Table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { licenseColumns } from "./licenseColumns";
import { useAppSelector } from "redux/hooks";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const LicensePage = () => {
  const { t } = useTranslation();
  const licensesData = useAppSelector((state) => state.licenses.data);
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    if (licensesData?.length > 0) {
      setInitialData(
        licensesData.map((data) => {
          return {
            id: data.softwareLicenseCode,
            softwareLicenseCode: data.softwareLicenseCode,
            productName: data.productName,
            lastOnlineActivationDate: data.lastOnlineActivationDate ? "Activated" : "Not activated",
          };
        })
      );
    }
    if (licensesData?.length === 0) {
      setInitialData([
        {
          id: "",
          softwareLicenseCode: "No license",
          productName: "No product/version",
          lastOnlineActivationDate: "No Status",
        },
      ]);
    }
  }, [licensesData]);

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">    
      <ScrollToTop />  
      <div className="msds-text-header-2-bold">
        {t("pages.myLicenses.title")}
      </div>
      <p className="msds-text-gray-10 msds-text-body-1">{t("pages.myLicenses.description")}</p>
      {initialData && (
        <Table
          initialData={initialData}
          rowClickBaseUrl={""}
          tableColumns={licenseColumns}
        />
      )}     
    </div>
  );
};

export default LicensePage;
