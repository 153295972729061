import { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { ButtonStyles, ButtonSize, LinkTypes } from "../../lib/enums";

const Button = ({
  id,
  label,
  href,
  size,
  styling,
  cssClass,
  eventCategory,
  eventLabel,
  disabled,
  buttonType,
  onClickHandler,
}: ButtonProps) => {

  const linkType = buttonType ? buttonType : LinkTypes.Hyperlink;
  const [disabledAttribute, setDisabledAttribute] = useState('');
  useEffect(() => {
    if(disabled) {
      setDisabledAttribute('disabled');      
      return;
    }        
    setDisabledAttribute('');    
  }, [disabled]);

  return (
    <>
      {linkType == LinkTypes.Hyperlink && (
        <a
            id={id}
            className={classNames(
              "msds-btn",
              size ? size : ButtonSize.Medium,
              styling ? styling : ButtonStyles.Primary,
              cssClass ? cssClass : ""
            )}
            onClick={onClickHandler}
            href={href}   
            data-event-category={eventCategory}
            data-event-label={eventLabel}      
            tabIndex={0}
          >
            {label}
        </a>
      )}
      {buttonType && linkType == LinkTypes.Button && (
        <button
            id={id}
            className={classNames(
              "msds-btn",
              size ? size : ButtonSize.Medium,
              styling ? styling : ButtonStyles.Primary,
              cssClass ? cssClass : ""
            )}
            onClick={onClickHandler}            
            data-event-category={eventCategory}
            data-event-label={eventLabel}      
            tabIndex={0}                        
            disabled={disabled}
          >
            {label}
        </button>
      )}
    </>
  );
};

type ButtonProps = {
  id?: string;
  label: string;
  href?: string;
  size?: ButtonSize;
  styling?: ButtonStyles;
  cssClass?: string;
  onClickHandler?: () => void;
  eventCategory?: string;
  eventLabel?: string;
  buttonType?: LinkTypes;
  disabled?: boolean;
};

export default Button;
