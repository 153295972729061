import { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, useExpanded, usePagination } from "react-table";
import GlobalFilter from "./GlobalFilter";
import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import "./Table.scss";
import { t } from "i18next";
import { getLanguageUrlParam } from "localization/helpers";
import {  useLocation } from "react-router-dom";


function Table({ initialData, rowClickBaseUrl, tableColumns }) {
  let navigate = useNavigate();
  const location = useLocation();
  const languageUrlParam = getLanguageUrlParam();
  const columns = useMemo(() => tableColumns, [initialData]);
  const [data, setData] = useState(initialData);
  const handleFilterData = (newData) => {
    setData(newData);
  };
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns: ["id"] },
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const tableRowClickEvent = (rowId) => {
    if (rowClickBaseUrl && rowId) {
      const scrollPosition = window.scrollY;
      sessionStorage.setItem('scrollPosition', scrollPosition.toString());
      let path = languageUrlParam + rowClickBaseUrl + rowId;
      navigate(path);
    }
  };

  //If we are back from the my user detail page then we scroll back to the table row previously selected
  //when clicking on thew pagination buttons, we scroll to the top of the table  
  useEffect(() => {
    const savedPosition = sessionStorage.getItem('scrollPosition');
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10));  // Parse back to number
      sessionStorage.removeItem('scrollPosition');
    }    
  }, []);
  //-------------------

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6 mb-2">
          <GlobalFilter
            data={initialData}
            handleFilterData={handleFilterData}
          />
        </div>
        <div className="col-12">
          <div className="msds-table">
            <table {...getTableProps()} className="msds-table__table-style">
              <thead className="msds-table__head">
                {headerGroups.map((headerGroup, index) => (
                  <tr
                    key={index}
                    className="msds-table__head-row"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <th
                          key={index}
                          className={"msds-table__header msds-table__header--" + column.id}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div id={"user-portal__e2e-table-column-" + column.id } className="msds-table__header-headline">
                            {column.render("Header")}
                            {column.id !== "expander" ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <span className="msds-table__header-order-icon">
                                    <svg className="msds-table__header-descending-order-icon">
                                      <use href={spritemap + "#right-arrow"} />
                                    </svg>
                                  </span>
                                ) : (
                                  <span className="msds-table__header-order-icon">
                                    <svg className="msds-table__header-acesnding-order-icon">
                                      <use href={spritemap + "#right-arrow"} />
                                    </svg>
                                  </span>
                                )
                              ) : (
                                <span className="msds-table__header-order-icon">
                                  <svg className="msds-table__header-descending-order-icon">
                                    <use href={spritemap + "#right-arrow"} />
                                  </svg>
                                  <svg className="msds-table__header-acesnding-order-icon">
                                    <use href={spritemap + "#right-arrow"} />
                                  </svg>
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="msds-table__body" {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      id={ "user-portal__e2e-table-click-row-" + row.id }
                      key={index}
                      className="msds-table__body-row"
                      {...row.getRowProps()}
                    >
                      {(row.canExpand || columns[0].id !== "expander") &&
                        row.cells.map((cell, index) => {
                          return (
                            <td                              
                              onClick={() => {     
                                if(index >= 0 || !row.canExpand) {
                                  tableRowClickEvent(row.values.id);
                                }
                              }}
                              key={index}
                              className={(location.pathname == "/licenses") ? "msds-table__body-row-data" : "msds-table__body-row-data msds-table__body-row-clickable"} 
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      {!row.canExpand && columns[0].id === "expander" && (
                        <>
                          <td className="msds-table__body-row-data-exapanded"></td>
                          <td
                            className="msds-table__body-row-data-exapanded"
                            colSpan={columns.length - 1}
                          >
                            {row.cells[1].value}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="msds-table__pagination msds-text-gray-10">
            <button
              id="user-portal__e2e-pagination-go-to-first-page"
              className="msds-table__pagination-prev-next-buttons msds-btn msds-btn--sm msds-btn--tertiary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>{" "}
            <button
              id="user-portal__e2e-pagination-go-to-previous-page"
              className="msds-table__pagination-prev-next-buttons msds-btn msds-btn--sm msds-btn--tertiary"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </button>{" "}
            <span className="msds-table__pagination-indicator d-none d-md-inline-block">
              <span className="msds-table__pagination-indicator-status">
                {t("tables.pagination.page")}{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <span>
                | {t("tables.pagination.goToPage")}:{" "}
                <input
                  id="user-portal__e2e-pagination-go-to-page"
                  className="msds-input__text-input"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
              </span>{" "}
            </span>
            <button
              id="user-portal__e2e-pagination-go-to-next-page"
              className="msds-table__pagination-prev-next-buttons msds-btn msds-btn--sm msds-btn--tertiary"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </button>{" "}
            <button
              id="user-portal__e2e-pagination-go-to-last-page"
              className="msds-table__pagination-prev-next-buttons msds-btn msds-btn--sm msds-btn--tertiary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span className="msds-table__pagination-indicator d-block d-md-none">
              <span className="msds-table__pagination-indicator-status">
                {t("tables.pagination.page")}{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <span>
                | {t("tables.pagination.goToPage")}:{" "}
                <input
                  id="user-portal__e2e-pagination-mobile-go-to-page"
                  className="msds-input__text-input"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
              </span>{" "}
            </span>
            <div className="msds-table__pagination-show-row-numbers">
              <select
                id="user-portal__e2e-pagination-show-number-of-pages"
                className="msds-select-input__select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {t("tables.pagination.show")} {pageSize}
                  </option>
                ))}
              </select>
              <div className="msds-select-input__icon">
                <svg>
                  <use href={spritemap + "#right-arrow"} />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
