import Button from "components/Button/Button";
import { ButtonSize, ButtonStyles } from "lib/enums";
import { getLanguageUrlParam } from "localization/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const languageUrlParam = getLanguageUrlParam();

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <div className="msds-text-header-2-bold mb-4">
        {t("pages.notFound.message")}
      </div>
      <Button
        label={t("buttons.backToHomepage")}
        size={ButtonSize.Medium}
        styling={ButtonStyles.Secondary}
        onClickHandler={() => {
          navigate(`${languageUrlParam}`);
        }}
      />
    </div>    
  );
};

export default NotFoundPage;
