import { Trans, useTranslation } from "react-i18next";
import FormButton from "components/FormComponents/FormButton/FormButton";
import i18n from "i18next";
import "./signup-form.scss";
import { ButtonSize, ButtonStyles, SignupSteps } from "lib/enums";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { goToStep, saveUserData } from "redux/signup/signupSlice";
import Button from "components/Button/Button";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import Input from "components/FormComponents/Input/Input";
import { ValidationTypes } from "components/FormComponents/formHooks/useValidation";
import Select from "components/FormComponents/Select/Select";
import CheckBoxes from "components/FormComponents/CheckBoxes/CheckBoxes";
import Form from "components/FormComponents/Form/Form";
import TermsConditionsDialog from "components/TermsConditionsDialog/TermsConditionsDialog";
import { useState } from "react";
import { MILESTONE_URL } from "config";

const EnterUserDataForm = ({ telephoneCodes }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const savedUserData = useAppSelector((state) => state.signup.enteredUserData);

  const email = useFormFieldState(savedUserData?.email);
  const firstName = useFormFieldState(savedUserData?.firstName);
  const lastName = useFormFieldState(savedUserData?.lastName);
  const telephoneCode = useFormFieldState(savedUserData?.telephoneCode);
  const telephone = useFormFieldState(savedUserData?.telephone);
  const title = useFormFieldState(savedUserData?.title);
  const password = useFormFieldState(savedUserData?.password);
  const confirmPassword = useFormFieldState(savedUserData?.confirmPassword);
  const termsConditionsConsent = useFormFieldState(savedUserData?.tcConsent);
  const marketingConsent = useFormFieldState(savedUserData?.consent);

  const requiredFormFields = [
    email,
    firstName,
    lastName,
    telephoneCode,
    telephone,
    title,
    password,
    confirmPassword,
    termsConditionsConsent,
  ];

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {  
    dispatch(
      saveUserData({
        email: email.state.value,
          firstName: firstName.state.value,
          lastName: lastName.state.value,
          telephoneCode: telephoneCode.state.value,
          telephone: telephone.state.value,
          title: title.state.value,
          password: password.state.value,
          confirmPassword: confirmPassword.state.value,
          consent: marketingConsent.state.value,
          consentText: t("forms.consents.marketing"),
          tcConsent: termsConditionsConsent.state.value,
          tcConsentText: t("forms.consents.termsConditions"),
      })
    );
    dispatch(goToStep(SignupSteps.OverviewAndSubmission));
  };

  const handleCloseDialog = () => setIsDialogOpen(false);

  const termsConditionsConsentCheckboxLabel = () => (
    <p>
      <Trans
        components={{
          terms: (
            <a
              key="href-1"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsDialogOpen(true);
              }}
            />
          ),
        }}
      >
        {i18n.t("forms.consents.termsConditionsRich")}
      </Trans>
    </p>
  );

  const termsConditionsConsentCheckboxData = [
    {
      id: "terms-conditions-consent",
      label: termsConditionsConsentCheckboxLabel(),
      value: "true",
    },
  ];

  const marketingConsentCheckboxLabel = () => (
    <p>
      <Trans
        components={{
          companies: (
            <a
              key="href-1"
              href={`${MILESTONE_URL()}/company-news/milestone/locations/`}
            />
          ),
          partners: (
            <a
              key="href-2"
              href={`${MILESTONE_URL()}/community/become-a-partner/`}
            />
          ),
          unsubscribe: (
            <a
              key="href-3"
              href="https://pardot.milestonesys.com/preferences?epc_hash=FHmZ_64RVPy5bNsGooMMwzaJbwHXRSUV_Qu1b1rElEo"
            />
          ),
        }}
      >
        {i18n.t("forms.consents.marketingRich")}
      </Trans>
    </p>
  );

  const marketingConsentCheckboxData = [
    {
      id: "marketing-consent",
      label: marketingConsentCheckboxLabel(),
      value: "true",
    },
  ];

  return (
    <>
      <TermsConditionsDialog
        isOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
      />
      <Form
        cssClasses="user-portal__signup-form"
        handleSubmit={handleSubmitForm}
        requiredFields={requiredFormFields}
      >
        <Input
          id="create-login-email-input"
          type="email"
          placeholder={t("forms.labels.email", { required: "*" })}
          label={t("forms.labels.email", { required: "*" })}
          initialValue={savedUserData?.email}
          initialMessage={{
            validation:
              "Please provide a valid email address from your business or organization.",
          }}
          validation={ValidationTypes.IsStrictEmail}
          makeInvalid={email.state.makeInvalid}
          maxLength={80}
          handleDataChange={(newValue, isValid) => {
            email.setState({ value: newValue, isValid: isValid });
          }}
        />
        <Input
          id="create-login-first-name-input"
          type="text"
          placeholder={t("forms.labels.firstName", { required: "*" })}
          label={t("forms.labels.firstName", { required: "*" })}
          initialValue={savedUserData?.firstName}
          initialMessage={{
            validation: "Please enter your first name.",
          }}
          validation={ValidationTypes.HasValue}
          makeInvalid={firstName.state.makeInvalid}
          maxLength={40}
          handleDataChange={(newValue, isValid) =>
            firstName.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <Input
          id="create-login-last-name-input"
          type="text"
          placeholder={t("forms.labels.lastName", { required: "*" })}
          label={t("forms.labels.lastName", { required: "*" })}
          initialValue={savedUserData?.lastName}
          initialMessage={{
            validation: "Please enter your last name.",
          }}
          validation={ValidationTypes.HasValue}
          makeInvalid={lastName.state.makeInvalid}
          maxLength={80}
          handleDataChange={(newValue, isValid) =>
            lastName.setState({ value: newValue, isValid: isValid })
          }
        />
        <div className="msds-telephone-field">
          <div className="col-6 col-md-5">
            <Select
              id="create-login-telephone-code-selection"
              options={telephoneCodes ?? []}
              defaultOption={{ label: "Code *", value: "" }}
              initialValue={savedUserData?.telephoneCode}
              initialMessage={{
                validation: "Please select a country code.",
              }}
              validation={ValidationTypes.HasValue}
              makeInvalid={telephoneCode.state.makeInvalid}
              handleDataChange={(newValue, isValid) =>
                telephoneCode.setState({
                  value: newValue,
                  isValid: isValid,
                })
              }
            />
          </div>
          <div className="col-6 col-md-7">
            <Input
              id="create-login-telephone-input"
              type="text"
              placeholder={t("forms.labels.telephone", {
                required: "*",
              })}
              label={t("forms.labels.telephone", {
                required: "*",
              })}
              initialValue={savedUserData?.telephone}
              initialMessage={{
                validation: "Please enter a valid telephone number.",
              }}
              validation={ValidationTypes.IsNumber}
              makeInvalid={telephone.state.makeInvalid}
              maxLength={35}
              handleDataChange={(newValue, isValid) =>
                telephone.setState({
                  value: newValue,
                  isValid: isValid,
                })
              }
            />
          </div>
        </div>
        <Input
          id="create-login-job-title-input"
          type="text"
          placeholder={t("forms.labels.jobTitle", { required: "*" })}
          label={t("forms.labels.jobTitle", { required: "*" })}
          initialValue={savedUserData?.title}
          initialMessage={{ validation: "Please enter your title." }}
          validation={ValidationTypes.HasValue}
          makeInvalid={title.state.makeInvalid}
          maxLength={128}
          handleDataChange={(newValue, isValid) =>
            title.setState({ value: newValue, isValid: isValid })
          }
        />
        <Input
          id="create-login-password-input"
          type="password"
          placeholder={t("forms.labels.password", { required: "*" })}
          label={t("forms.labels.password", { required: "*" })}
          initialValue={savedUserData?.password}
          initialMessage={{
            validation:
              "Please enter a password. Password must be 8 characters or more, contain upper- and lowercase letters, at least 1 number and 1 special character.",
          }}
          validation={ValidationTypes.IsPassword}
          makeInvalid={password.state.makeInvalid}
          handleDataChange={(newValue, isValid) =>
            password.setState({ value: newValue, isValid: isValid })
          }
        />
        <Input
          id="create-login-confirm-password-input"
          type="password"
          placeholder={t("forms.labels.confirmPassword", {
            required: "*",
          })}
          label={t("forms.labels.confirmPassword", { required: "*" })}
          initialValue={savedUserData?.confirmPassword}
          initialMessage={{ validation: "Password does not match." }}
          validation={ValidationTypes.IsConfirmPassword}
          makeInvalid={confirmPassword.state.makeInvalid}
          data={{ password: password.state.value }}
          handleDataChange={(newValue, isValid) =>
            confirmPassword.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <p className="msds-text-gray-10 msds-text-body-1">
          <Trans
            components={{
              anchor: <a href={`${MILESTONE_URL()}/privacy-policy/`} />,
            }}
          >
            {t("forms.privacyNoticeRich")}
          </Trans>
        </p>
        <CheckBoxes
          initialSelected={termsConditionsConsent.state.value as string[]}
          checkboxes={termsConditionsConsentCheckboxData}
          validation={ValidationTypes.IsCheckboxSelected}
          makeInvalid={termsConditionsConsent.state.makeInvalid}
          handleChangeData={(newSelected, isValid) =>
            termsConditionsConsent.setState({
              value: newSelected,
              isValid: isValid,
            })
          }
        />
        <CheckBoxes
          initialSelected={marketingConsent.state.value as string[]}
          checkboxes={marketingConsentCheckboxData}
          handleChangeData={(newSelected, isValid) =>
            marketingConsent.setState({
              value: newSelected,
              isValid: isValid,
            })
          }
        />
        <section className="user-information-page__actions">
          <Button
            id="create-login-submit-button"
            label={t("buttons.back")}
            size={ButtonSize.Large}
            styling={ButtonStyles.Secondary}
            onClickHandler={() => dispatch(goToStep(SignupSteps.SelectCompany))}
          />
          <FormButton
            id="create-login-submit-button"
            label={t("buttons.next")}
            size={ButtonSize.Large}
            styling={ButtonStyles.Tertiary}
          />
        </section>
      </Form>
    </>
  );
};

export default EnterUserDataForm;
