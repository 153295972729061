import classNames from "classnames/bind";
import Button from "components/Button/Button";
import { ButtonStyles, SignupSteps } from "lib/enums";
import { SignupMatchedCompaniesResponse } from "lib/types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch } from "redux/hooks";
import { goToStep, selectCompany } from "redux/signup/signupSlice";
import "./select-company.scss";
import { MILESTONE_URL } from "config";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const SelectCompany = ({
  matchedCompanies,
}: {
  matchedCompanies: SignupMatchedCompaniesResponse;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isCompanySelected, setIsCompanySelected] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMesssage] = useState<boolean>(false);

  const description = (
    <>
      <p>{t("pages.signUp.selectCompany.description.message1")}</p>
      <p>
      <Trans
          components={{
            supportLink: (
              <a 
                target="_blank"
                href={`${MILESTONE_URL()}/support/contact-us/sales-support/`}
              />
            ),
          }}
        >
          {t(
            "pages.signUp.selectCompany.description.message2"
          )}
        </Trans>
      </p>
    </>
  );

  return (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">    
      <ScrollToTop />
      <div className="msds-text-header-2-bold">
        {t("pages.signUp.selectCompany.title")}
      </div>      
      <p className="msds-text-gray-10 msds-text-body-1">
        {matchedCompanies.length == 1 ? t("pages.signUp.selectCompany.oneCompanyFoundMessage") : description}</p>
      <div className="company-list__count msds-text-header-5 msds-text-gray-10">
        {t("pages.signUp.selectCompany.foundCompanies", {
          count: matchedCompanies.length,
        })}
      </div>
      <div
        className={classNames(
          "company-list__selections p-0",
          matchedCompanies.length > 4 && "company-list__selections--long"
        )}
      >
        {matchedCompanies.map((company) => {
          return (
            <div
              key={company.companyNumber}
              className="company-list__item"
            >
              <div className="msds-radio-button">
                <input
                  className="msds-radio-button__input"
                  id={company.companyNumber}
                  type="radio"
                  value={company.companyNumber}
                  name="company-list-group"
                  onChange={() => {
                    dispatch(selectCompany(company));
                    setIsCompanySelected(true);
                    setShowErrorMesssage(false);
                  }}
                />
                <label
                  className="msds-radio-button__label msds-text-body-1 msds-text-gray-10"
                  htmlFor={company.companyNumber}
                >
                  {company.name +
                    ", " +
                    company.address +
                    ", " +
                    company.postalCode +
                    ", " +
                    company.locality}
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="company-list__not-on-list-button msds-text-body-1 my-6"
        onClick={() => {
          dispatch(goToStep(SignupSteps.ContactSupport));
        }}
      >
        {matchedCompanies.length === 1
          ? "This is not my company"
          : "My company is not on the list"}
      </button>
      <div className="company-list__actions">
        <Button
          label={t("buttons.back")}
          styling={ButtonStyles.Secondary}
          onClickHandler={() => {
            dispatch(goToStep(SignupSteps.EnterCompanyData));
          }}
        />
        <Button
          label={t("buttons.next")}
          styling={ButtonStyles.Tertiary}
          onClickHandler={() => {
            if (isCompanySelected)
              dispatch(goToStep(SignupSteps.EnterUserData));
            else setShowErrorMesssage(true);
          }}
        />
      </div>
      <p className="msds-text-danger-red-1">
        {showErrorMessage &&
          t("pages.signUp.selectCompany.noCompanySelected")}
      </p>
    </div>        
  );
};

export default SelectCompany;
