import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { useParams, useNavigate } from "react-router-dom";
import UserDetailsForm from "../../components/UserDetailsForm/UserDetailsForm";
import Button from "components/Button/Button";
import { ButtonSize, ButtonStyles } from "../../lib/enums";
import { CompanyUserType } from "lib/types";
import { getUserTemporaryProfileAdminThunk } from "../../redux/api/adminAddNewUserRequest/actions";
import { Status } from "../../lib/enums";
import UnauthorizedBanner from "components/UnauthorizedBanner/UnauthorizedBanner";
import { useTranslation } from "react-i18next";
import { getLanguageUrlParam } from "localization/helpers";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

function AdminAddNewUserRequestPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activationCode } = useParams();
  const languageUrlParam = getLanguageUrlParam();

  const userTempData = useAppSelector(
    (state) => state.adminAddNewUserRequest.tempNewUserData
  );

  const allRoles = useAppSelector((state) => state.userRoles.allRoles);

  const postNewUserDataStatus = useAppSelector(
    (state) => state.adminAddNewUserRequest.postNewUserDataStatus
  );

  const getNewUserTempDataErrorMessage = useAppSelector(
    (state) =>
      state.adminAddNewUserRequest.backendErrors.getNewUserTempDataErrorMsg
  );

  const isAdmin = useAppSelector((state) => state.user.isAdmin);
  const myRoles = useAppSelector(
    (state) => state.userDetails.myDetails?.userRoles
  );

  const initialUserData: CompanyUserType = {
    firstName: "",
    lastName: "",
    companyName: "",
    createdDate: "",
    email: "",
    id: "",
    jobTitle: "",
    mobile: "",
    partnerClassification: "",
    partnerId: "",
    prefferedLanguageId: "",
    telephone: "",
    userRoles: [],
    username: "",
    noLongerWithCompany: "",
    isCompanyVerified: false,
    isMilestoneEmployee: false
  };

  const [userData, setUserData] = useState(initialUserData);

  useEffect(() => {
    dispatch(getUserTemporaryProfileAdminThunk(activationCode));
  }, []);

  useEffect(() => {
    setUserData({
      ...userData,
      firstName: userTempData?.firstName,
      lastName: userTempData?.lastName,
      email: userTempData?.email,
      companyName: userTempData?.companyName,
    } as CompanyUserType);
  }, [userTempData]);

  const header = t("pages.adminAddNewUserRequest.titleSuccess", {
    firstName: userData.firstName,
    lastName: userData.lastName,
  });

  const description = t("pages.adminAddNewUserRequest.descriptionSuccess", {
    firstName: userData.firstName,
    lastName: userData.lastName,
    companyName: userData.companyName,
    email: userData.email,
  });

  const adminAddsNewUserSuccess = (
    <div className="col-10 offset-1 col-lg-6 offset-lg-1">
      <ScrollToTop />
      <div className="user-portal__create-login-form--success">
        <div className="msds-text-header-2-bold">
          {header}
        </div>
        <p className="msds-text-gray-10 msds-text-body-1">
          {description}
        </p>              
        <Button
          label={t("buttons.backToList")}
          onClickHandler={() => {
            navigate(`${languageUrlParam}/user-list`);
          }}
          size={ButtonSize.Medium}
          styling={ButtonStyles.Secondary}
        />
      </div>          
    </div>
  );

  const backendErrorMessage = (
    <div className="msds-input msds-input--error col-12 text-center">
      <p className="msds-input__info-message">
        {getNewUserTempDataErrorMessage}
      </p>
    </div>
  );

  if (postNewUserDataStatus === Status.Success) {
    return adminAddsNewUserSuccess;
  } else {
    return (
      <div className="col-10 offset-1 col-lg-6 offset-lg-1">
        <ScrollToTop />
        {isAdmin ? (
          <>
            <div className="msds-text-header-2-bold">
              {t("pages.adminAddNewUserRequest.title")}
            </div>
            <p className="msds-text-gray-10 msds-text-body-1">
              {t("pages.adminAddNewUserRequest.description")}
            </p>            
            {myRoles != null && (
              <>
                {getNewUserTempDataErrorMessage !== "" &&
                  backendErrorMessage}
                <div className="col-12 mt-6">
                  {userTempData && (
                    <UserDetailsForm
                      activationCode={activationCode}
                      userData={userData}
                      allRoles={allRoles}
                    />
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <UnauthorizedBanner />
        )}
      </div>
    );
  }
}

export default AdminAddNewUserRequestPage;
