import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";
import CreateNewPasswordPage from "pages/CreateNewPasswordPage/CreateNewPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage/ResetPasswordPage";
import MyDetailsPage from "../pages/MyDetailsPage/MyDetailsPage";
import UserAccountActivationPage from "../pages/UserAccountActivationPage/UserAccountActivationPage";
import UserListPage from "../pages/UserListPage/UserListPage";
import UserDetailsPage from "../pages/UserDetailsPage/UserDetailsPage";
import AccountsApprovalListPage from "pages/SignUpPage/SingupSteps/7-AccountsApprovalList/AccountsApprovalListPage";
import AccountsApprovalPage from "pages/SignUpPage/SingupSteps/7-AccountsApprovalList/AccountsApprovalPage";
import AdminAddNewUserRequestPage from "pages/AdminAddNewUserRequestPage/AdminAddNewUserRequestPage";
import UserTemporaryProfilePage from "pages/UserTemporaryProfilePage/UserTemporaryProfilePage";
import AdminDeclineUserApplicationPage from "pages/AdminDeclineUserApplicationPage/AdminDeclineUserApplicationPage";
import UserUpdateEmailConfirmationPage from "pages/UserUpdatedEmailConfirmationPage/UserUpdatedEmailConfirmationPage";
import Layout from "../components/Layout/Layout";
import NotFoundPage from "pages/NotFOundPage/NotFoundPage";
import VerifyCompanyPage from "pages/VerifyCompanyPage/VerifyCompanyPage";
import SignUpPage from "pages/SignUpPage/SignUpPage";
import { getLanguageUrlParam } from "localization/helpers";
import LicensePage from "pages/LicensePage/LicensePage";
import { RequireAuthorization } from "components/Authentication/RequireAuthorization";
import CookiePolicyPage from "pages/CookiePolicyPage/CookiePolicyPage";
import ClaimLicensePage from "pages/LicenseClaimPage/LicenseClaimPage";
import React from "react";
import { RegisterLicenseFlowData } from "lib/types";
import { getRegisterLicenseFlowDataFromSessionStorage, getSoftwareLicenseAndTokenFromUrlString } from "lib/helpers";

export const routes = [
  { path: '/cookie-policy', element: <CookiePolicyPage />, requireAuthorization: false },
  { path: '/signup', element: <SignUpPage />, requireAuthorization: false },
  { path: '/create-new-password/:resetCode', element: <CreateNewPasswordPage />, requireAuthorization: false },
  { path: '/create-new-password', element: <CreateNewPasswordPage />, requireAuthorization: false },
  { path: '/login-page/activate-profile/:activationCode', element: <UserAccountActivationPage />, requireAuthorization: false },
  { path: '/login-page/activate-profile-user-details/:id', element: <UserTemporaryProfilePage />, requireAuthorization: false },
  { path: '/reset-password', element: <ResetPasswordPage />, requireAuthorization: false },
  { path: '/updated-email-confirmation/:id', element: <UserUpdateEmailConfirmationPage />, requireAuthorization: false },
  { path: '/verify-company/', element: <VerifyCompanyPage />, requireAuthorization: false, hasNoNavigation: false },
  { path: '/verify-company/:contactId', element: <VerifyCompanyPage />, requireAuthorization: false, hasNoNavigation: false },
  { path: '/edit-user-details', element: <MyDetailsPage />, requireAuthorization: true },
  { path: '/user-list', element: <UserListPage />, requireAuthorization: true },
  { path: '/user-list-details/:uId', element: <UserDetailsPage />, requireAuthorization: true },
  { path: '/user-list-details', element: <UserDetailsPage />, requireAuthorization: true },
  { path: '/accounts-approval-list', element: <AccountsApprovalListPage />, requireAuthorization: true },
  { path: '/accounts-approval/:ac', element: <AccountsApprovalPage />, requireAuthorization: true },
  { path: '/login-page/admin/user-details/:activationCode', element: <AdminAddNewUserRequestPage />, requireAuthorization: true },
  { path: '/login-page/admin/decline-user/:id', element: <AdminDeclineUserApplicationPage />, requireAuthorization: true },
  { path: '/register-license/slc=:slc&token=:token', element: <ClaimLicensePage />, requireAuthorization: true },
  { path: '/licenses', element: <LicensePage />, requireAuthorization: true }
]

const Routing = () => {
  const languageUrlParam = getLanguageUrlParam();
  const [searchParams, setsearchParams] = useSearchParams();
  const windowUrl = window.location.href;
  const urlHasRegisterLicense = (windowUrl.includes("/register-license") || windowUrl.includes("/Register-License"));
  const urlHasSoftwareLicenseAndTokenQueryParams = (windowUrl.includes("?slc") && windowUrl.includes("&token"))
  const urlContainsRegisterLicenseAndQueryParams = (urlHasRegisterLicense && urlHasSoftwareLicenseAndTokenQueryParams);

  if (urlContainsRegisterLicenseAndQueryParams) {
    const indexToStripUrlFrom = windowUrl.lastIndexOf('?');
    const strippedWindowUrl = windowUrl.substring(0, indexToStripUrlFrom);
    const newUrl = `${strippedWindowUrl}/slc=${searchParams.get('slc')}&token=${searchParams.get('token')}`
    window.location.href = newUrl
  }

  const storeRegisterLicenseFlowinSession = (slc: string, token: string) => {

    const registerlicenseFlowData: RegisterLicenseFlowData = {
      slc: slc,
      token: token,
      isRegisterLicenseFlow: true,
      createdAt: Date.now()
    };
    sessionStorage.setItem("registerLicenseFlowData", JSON.stringify(registerlicenseFlowData));
  }

  if (urlHasRegisterLicense) {
    const registerLicenseFlowData = getRegisterLicenseFlowDataFromSessionStorage();

    if (!registerLicenseFlowData) {
      const softwareLicenseAndTokenFromUrlString = getSoftwareLicenseAndTokenFromUrlString(windowUrl)
      storeRegisterLicenseFlowinSession(softwareLicenseAndTokenFromUrlString.slc, softwareLicenseAndTokenFromUrlString.token)
    }
  }
  return (
    <Routes >
      <Route path="/" element={<Layout />}>
        {routes.map(route =>
          <Route key={route.path} path={`${languageUrlParam}${route.path}`} element={
            <>
              {route.requireAuthorization && <RequireAuthorization>{route.element}</RequireAuthorization>}
              {!route.requireAuthorization && route.element}
            </>}
          />
        )}
        <Route
          path={`${languageUrlParam}`}
          element={
            <RequireAuthorization>
              <Navigate replace to="/edit-user-details" />
            </RequireAuthorization>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default Routing;
