import { useAppSelector } from "redux/hooks";
import { selectIsAuthenticated } from "redux/user/userSlice";
import { NavLink, useLocation } from "react-router-dom";
import { getLanguageUrlParam } from "localization/helpers";
import { useTranslation } from "react-i18next";
import { MILESTONE_URL } from "config";
import "./header.scss";
import "./headersectionblock";
import SignedInMenu from "./SignedInMenu";

const Header = (props: HeaderProps) => {
  const isAdmin = useAppSelector((state) => state.user.isAdmin);
  const isInternalUserAdmin = useAppSelector((state) => state.user.isInternalUserAdmin);  
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const languageUrlParam = getLanguageUrlParam();
  const { t } = useTranslation();
  const location = useLocation();
  const urlsToHide = ["/signup", "/reset-password"];

  const shouldHideInHeader = (currentUrl: string, urlsToHide: string[]): boolean => {
    return urlsToHide.some((url) => currentUrl.startsWith(url));
  };

  const setCssClasses = (url, page) => {
    let baseCssClasses = "header-section-block__navigation-menu header-section-block__navigation-menu--level1"
    if(url.startsWith(page)) {
      return baseCssClasses + " header-section-block__navigation-menu--level1-parent";
    }
    
    return baseCssClasses;
  }

  return (
    <div id="HeaderSection" className="header-section-sticky">
      <div className="row g-0 no-gutters">
        <div className="header-section-block col-12">
            <div className="header-section-block__container header-section-block__container--desktop top-margin-for-old-pages">
              <a href={MILESTONE_URL()} className="header-section-block__milestone-logo"></a>
              {!shouldHideInHeader(location.pathname, urlsToHide) && (
                <nav id="MainMenu" className="header-section-block__navigation">
                <div className="header-section-block__navigation-menus">
                    <div className="d-inline-block d-md-none hidden-lg header-section-block__navigation-menus-button-close-container">
                        <div className="header-section-block__navigation-menus-button-close ms-icon">
                          <svg viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <path fill="unset" d="M63.646 54.433L99.079 19 109 28.921 73.567 64.354 108.29 99.08 98.371 109 63.645 74.276 29.63 108.29l-9.921-9.92 34.015-34.017L19 29.63l9.921-9.921z"/>
                          </svg>
                        </div>
                    </div>
                    {!isAuthenticated && (
                      <>
                        <div className="header-section-block__navigation-menu-container">
                          <div className={setCssClasses(location.pathname, "/signup")}>
                            <NavLink className="header-section-block__navigation-menu-label" to={`${languageUrlParam}/signup`}>
                              {t("navigation.tabs.createLogin")}
                            </NavLink>                                                              
                          </div>
                        </div>                        
                        <div className="header-section-block__navigation-menu-container">
                          <div className={setCssClasses(location.pathname, "/reset-password")}>
                            <NavLink className="header-section-block__navigation-menu-label" to={`${languageUrlParam}/reset-password`}>
                              {t("navigation.tabs.forgotPassword")}
                            </NavLink>                                                            
                          </div>      
                        </div>                                                                      
                      </>
                    )}
                    {isAuthenticated && (
                      <>
                        <div className="header-section-block__navigation-menu-container">
                          <div className={setCssClasses(location.pathname, "/edit-user-details")}>
                              <NavLink className="header-section-block__navigation-menu-label" to={`${languageUrlParam}/edit-user-details`}>
                                {t("navigation.tabs.myDetails")}
                              </NavLink>                                                              
                          </div>
                        </div>                        
                        {isAdmin && (
                          <div className="header-section-block__navigation-menu-container">
                            <div className={setCssClasses(location.pathname, "/user-list")}>
                              <NavLink className="header-section-block__navigation-menu-label" to={`${languageUrlParam}/user-list`}>
                                {t("navigation.tabs.myUsers")}
                              </NavLink>                                                            
                            </div>  
                          </div>                          
                        )} 
                        {isInternalUserAdmin && (
                          <div className="header-section-block__navigation-menu-container">
                            <div className={setCssClasses(location.pathname, "/accounts-approval-list")}>
                              <NavLink className="header-section-block__navigation-menu-label" to={`${languageUrlParam}/accounts-approval-list`}>
                                {t("navigation.tabs.accountsApproval")}
                              </NavLink>                                                            
                            </div>  
                          </div>                          
                        )}    
                        <div className="header-section-block__burger-links">
                          <div className="d-block d-sm-none hidden-sm header-section-block__login-button">                           
                            {!isAuthenticated && (
                              <a className="button-tile-block msds-btn msds-btn--secondary msds-btn--sm" 
                                onClick={props.onLoginButtonClick}>
                                Log in
                              </a>
                            )}
                          </div>
                          {
                            isAuthenticated && <SignedInMenu onLogoutClick={props.onLoginButtonClick}/>
                          }
                        </div> 
                      </>                     
                    )}                                                                             
                </div>
              </nav>   
              )}                           
              <div className="header-section-block__links">
                {!shouldHideInHeader(location.pathname, urlsToHide) && (     
                  <>                               
                  {
                    isAuthenticated ? <SignedInMenu onLogoutClick={props.onLoginButtonClick}
                    />
                    :
                    <div className="header-section-block__login-button">
                        <a className="button-tile-block msds-btn msds-btn--secondary msds-btn--sm" 
                          onClick={props.onLoginButtonClick}>
                          Log In
                        </a>
                    </div>  
                  }                    
                    <button className="header-section-block__links-burger-menu-link" type="button">
                      <div className="ms-icon">
                        <svg viewBox="0 0 132 132" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 30h120v8H4zm0 30h120v8H4zm0 30h120v8H4z" fillRule="evenodd"/>
                        </svg>
                      </div>
                    </button>
                  </> 
                )}                 
              </div>                
            </div>
          </div>
      </div>
    </div>   
  );
};

export type HeaderProps = {
  onLoginButtonClick: () => void;
};

export default Header;