import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormButton from "../FormComponents/FormButton/FormButton";
import { updateMyDetailsDataThunk } from "redux/api/userDetails/actions";
import { resetUpdateMyDetailsDataStatus } from "redux/api/userDetails/userDetailsSlice";
import { MyDetailsType } from "lib/types";
import { ButtonSize, ButtonStyles, Status } from "lib/enums";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import Form from "components/FormComponents/Form/Form";
import Input from "components/FormComponents/Input/Input";
import Select from "components/FormComponents/Select/Select";
import { ValidationTypes } from "components/FormComponents/formHooks/useValidation";
import "./my-details-form.scss";
import { MILESTONE_URL } from "config";

const MyDetailsForm = () => {
  const dispatch = useAppDispatch();

  const myDetails = useAppSelector((state) => state.userDetails.myDetails);

  const updateMyDetailsDataStatus = useAppSelector(
    (state) => state.userDetails.updateMyDetailsDataStatus
  );

  const updatemyDetailsDataErrorMsg = useAppSelector(
    (state) =>
      state.userDetails.backendErrorMessages.updateMyDetailsDataErrorMsg
  );

  const preferredLanguages = useAppSelector(
    (state) => state.configutationLists.PreferredLanguages
  );

  const companyId = useFormFieldState(myDetails?.partnerId);
  const companyName = useFormFieldState(myDetails?.companyName);
  const firstName = useFormFieldState(myDetails?.firstName);
  const lastName = useFormFieldState(myDetails?.lastName);
  const jobTitle = useFormFieldState(myDetails?.jobTitle);
  const telephone = useFormFieldState(myDetails?.telephone);
  const mobile = useFormFieldState(myDetails?.mobile);
  const preferredLanguage = useFormFieldState(myDetails?.prefferedLanguageId);
  const email = useFormFieldState(myDetails?.email);
  const userName = useFormFieldState(myDetails?.username);
  
  const requiredFormFields = [
    firstName,
    lastName,
    email,
    userName,
  ];
  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    const updateMyDetailsPayload = {
      firstName: firstName.state.value ?? "",
      lastName: lastName.state.value ?? "",
      jobTitle: jobTitle.state.value ?? "",
      telephone: telephone.state.value ?? "",
      mobile: mobile.state.value ?? "",
      prefferedLanguageId: preferredLanguage.state.value ?? "",
      email: email.state.value ?? "",
      username: userName.state.value ?? "",
    };
    dispatch(updateMyDetailsDataThunk(updateMyDetailsPayload as MyDetailsType));
  };

  useEffect(() => {
    return () => {
      dispatch(resetUpdateMyDetailsDataStatus());
    };
  }, [dispatch]);  

  return (
    <Form
      cssClasses="user-portal__edit-user-details-form"
      handleSubmit={handleSubmitForm}
      requiredFields={requiredFormFields}
    >
      <Input
        id="my-details-form__e2e-company-id-input"
        type="text"
        placeholder={t("forms.labels.companyId", { required: "" })}
        initialValue={myDetails?.partnerId}
        label={t("forms.labels.companyId", { required: "" })}
        isDisabled={true}
        handleDataChange={(newValue, isValid) => {
          companyId.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Input
        id="my-details-form__e2e-company-name-input"
        type="text"
        initialValue={myDetails?.companyName}
        placeholder={t("forms.labels.company", { required: "" })}
        label={t("forms.labels.company", { required: "" })}
        isDisabled={true}
        handleDataChange={(newValue, isValid) => {
          companyName.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Input
        id="my-details-form__e2e-first-name-input"
        type="text"
        initialValue={myDetails?.firstName}
        placeholder={t("forms.labels.firstName", { required: "*" })}
        label={t("forms.labels.firstName", { required: "*" })}
        initialMessage={{ validation: "Please enter your first name." }}
        validation={ValidationTypes.HasValue}
        makeInvalid={firstName.state.makeInvalid}
        maxLength={40}
        handleDataChange={(newValue, isValid) => {
          firstName.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Input
        id="my-details-form__e2e-last-name-input"
        type="text"
        initialValue={myDetails?.lastName}
        placeholder={t("forms.labels.lastName", { required: "*" })}
        label={t("forms.labels.lastName", { required: "*" })}
        initialMessage={{ validation: "Please enter your last name." }}
        validation={ValidationTypes.HasValue}
        maxLength={80}
        handleDataChange={(newValue, isValid) => {
          lastName.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Input
        id="my-details-form__e2e-job-title-input"
        type="text"
        initialValue={myDetails?.jobTitle}
        placeholder={t("forms.labels.jobTitle", { required: "" })}
        label={t("forms.labels.jobTitle", { required: "" })}
        maxLength={128}
        handleDataChange={(newValue, isValid) => {
          jobTitle.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Input
        id="my-details-form__e2e-telephone-input"
        type="text"
        initialValue={myDetails?.telephone}
        placeholder={t("forms.labels.telephone", { required: "" })}
        label={t("forms.labels.telephone", { required: "" })}
        maxLength={40}
        handleDataChange={(newValue, isValid) => {
          telephone.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Input
        id="my-details-form__e2e-mobile-input"
        type="text"
        initialValue={myDetails?.mobile}
        placeholder={t("forms.labels.mobile", { required: "" })}
        label={t("forms.labels.mobile", { required: "" })}
        maxLength={40}
        handleDataChange={(newValue, isValid) => {
          mobile.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Select
        id="my-details-form__e2e-language-select"
        options={preferredLanguages ?? []}
        defaultOption={{ label: "Select your preferred language", value: "" }}
        initialValue={myDetails?.prefferedLanguageId}
        handleDataChange={(newValue, isValid) => {
          preferredLanguage.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Input
        id="my-details-form__e2e-email-input"
        type="text"  
        isDisabled={myDetails?.isMilestoneEmployee}    
        initialValue={myDetails?.email}
        placeholder={t("forms.labels.email", { required: "*" })}
        label={t("forms.labels.email", { required: "*" })}
        initialMessage={{ validation: "Please enter a valid email address." }}
        validation={ValidationTypes.IsEmail}
        makeInvalid={email.state.makeInvalid}
        maxLength={80}
        handleDataChange={(newValue, isValid) => {
          email.setState({ value: newValue, isValid: isValid });
        }}
      />
      <Input
        id="my-details-form__e2e-user-name-input"
        type="text"
        isDisabled={true}
        initialValue={myDetails?.username}
        placeholder={t("forms.labels.userName", { required: "*" })}
        label={t("forms.labels.userName", { required: "*" })}
        initialMessage={{ validation: "Please enter a valid username." }}
        makeInvalid={userName.state.makeInvalid}
        handleDataChange={(newValue, isValid) => {
          userName.setState({ value: newValue, isValid: isValid });
        }}
      />
      {myDetails?.isMilestoneEmployee && (
        <p>
          <Trans>
            {t("forms.milestoneEmployeeEmailUsernameNote")}
          </Trans>
        </p>
      )}      
      <p className="msds-text-gray-10 msds-text-body-1">
        <Trans
          components={{
            anchor: <a href={`${MILESTONE_URL()}/privacy-policy/`} />,
          }}
        >
          {t("forms.privacyNoticeRich")}
        </Trans>
      </p>
      <p className="msds-text-gray-10 msds-text-body-1">{t("forms.mandatoryFieldsInformation")}</p>
      {updateMyDetailsDataStatus === Status.Rejected && (
        <div className="msds-text-danger-red-1">
          {updatemyDetailsDataErrorMsg
            ? updatemyDetailsDataErrorMsg
            : "Error updating your details, please try again later. "}
        </div>
      )}
      <FormButton
        id="my-details-form__e2e-save-button"
        label={t("buttons.save")}
        size={ButtonSize.Large}
        styling={ButtonStyles.Secondary}
      />
    </Form>
  );
};

export default MyDetailsForm;
