const de = {
  navigation: {
    footerNavigation: {
      compliance: "de - For any compliance matters please contact us by sending an email to compliance@milestonesys.com",
      copyright: "de - Copyright © {{year}} Milestone Systems A/S. All rights reserved."
    },
    tabs: {
      createLogin: "de -Create login",
      forgotPassword: "de - Forgot password",
      myDetails: "de - My details",
      myUsers: "de - My users",
      myLicenses: "de - My licenses",
    },
  },
  userRoles: {
    leadPortalUser: {
      roleKey: "Lead Portal User",
      roleDescription: ""
    },
    licenseLoopUpUser: {
      roleKey: "",
      roleDescription: ""
    },
    marketPlaceAdmin: {roleKey: "", roleDescription: ""},
    myMilstoneUser: {roleKey: "de - MyMilestone User", roleDescription: "de - Allows the user to access the general Milestone portal."},
    myMilestoneAdmin: {roleKey: "de - MyMilestone Admin", roleDescription: "de - Allows the user to add/edit/revoke access from users under ‘My Users’ on the portal."},
    saleStatusUser: {roleKey: "de - Sale Status User", roleDescription: "de - Allows the user to view the purchases made on their account."},
    webshopUser: {roleKey: "de - WebShop User", roleDescription: "de - Allows the user to place orders and create quotes on Milestone Store.*"},
    webshopQuotesOnly: {roleKey: "de - WebShop Quotes Only", roleDescription: "de - Allows the user to create quotes only on Milestone Store.**"},
    licenseAdmin: {roleKey: "de - License Admin", roleDescription: "de - Allows the user to administer and allocate licenses to users within their account.*"},
    applicationTrackerUser: {roleKey: "de - Application Tracker User", roleDescription: "de -Allows the user to access the Application track briefcase which contains all the resources needed to build/verify/market their integrations."},
    projectRegistrationUser: {roleKey: "de - Project Registration User", roleDescription: "de - Allows the user to register projects."},
    lisenceUser: {roleKey: "de - License User", roleDescription: "de - Allows the user to activate licenses that are registered to their account on CUDA/Software registration."},
    marketPlaceEditor: {roleKey: "", roleDescription: ""},
    distributorUser: {roleKey: "de - Distributor User", roleDescription: "de - Allows the user to access the distributor portal."},
    distributerManager: {roleKey: "de - Distributor Manager", roleDescription: "de - Allows the user to access targets and backend information on their account."},
    resellerUser: {roleKey: "de - Reseller User", roleDescription: "de - Allows the user to access the reseller portal."},
    hardwareTrackerUser: {roleKey: "de - Hardware Tracker User", roleDescription: "de - Allows the user to access the Hardware track briefcase which contains all the resources needed to build/verify/market their integrations."},    
    deviceTrackerUser: {roleKey: "de - Device Tracker User", roleDescription: "de - Allows the user to access the Device track briefcase which contains all the resources needed to build/verify/market their integrations."},
    systemBuilderTrackerUser: {roleKey: "de - System Builder Tracker User", roleDescription: "de - Allows the user to access the System Builder track briefcase which contains all the resources needed to build/verify/market their integrations."},
  },
  pages: {
    signUp: {
      title: "Erstellen Sie ein Milestone-Login",
      alreadyHaveAccount:
        "<0>Sie haben bereits ein Konto?</0> <1>Hier anmelden</1>",
        
      distributorResellerAccount: "<0>de - Are you a distributor or re-seller?</0> <1>Please click this link for the right onboarding</1>",
      subtitleCompanyDataStep:
        "de - Company information: Please tell us about your company and select your company in the next step.",
      subtitleUserDataStep:
        "de - Please tell us a bit about yourself and create a password for your My Milestone account.",
      confirmation: {
        titleCompanyDetected:
          "de - Looks like {{company}} already has a My Milestone account",
        titleNoCompanyDetected: "de - Verify your email address",
        descriptionCompanydetected: {
          message1:
            "de - To get access to the relevant tools and resources, you need to be added as a user of {{company}}'s account. Your company's admin must approve you as a user, so we have sent a request to them via email.",
          message2:
            "de - Once the admin has approved you as a user, you will receive an email from us and can start using My Milestone.",
        },
        descriptionNoCompanyDetected: {
          message1:
            "de - An email containing a verification link has been sent to your email address. Please click on the link to verify your email. If you have not received the email, please check your Spam folder.​",
          message2:
            "de - Once you have verified your email address and your account has been approved, you can start using My Milestone.",
        },
      },
      selectCompany: {
        title: "de - Please select your Company on the list",
        description: {
          message1:
            "de - More than one company with the name you entered was found in our system, please pick yur company on the list.",
          message2:
            "de - If you can't find your company on the list please go back and check the spelling and try again, or contact <1>Milestone Sales Support</1>",
        },
        oneCompanyFoundMessage: "de - Select your company and click NEXT.​",
        foundCompanies: "de - {{count}} COMPANIES FOUND",
        noCompanySelected: "de - Please select a company.",
      },
      noMatch: {
        title: "de - No company matches",
        titleContactSupportConfirmation: "We will get back to you shortly",
        description: {
          message1:
            "de - Please check that the information you have entered is correct.",
        },
        descriptionContactSupportConfirmation: {
          message1:
            "de - Thank you! We will process your company information and get back to you within 1-2 business days.",
          message2: "de - While we process your company information, you can log in My Milestone as usual.",
          message3:
            "de - If you have any questions, please contact <support>Milestone Sales Support</support>.",
        },
        labels: {
          email: "de - Email",
          firstName: "de - First Name",
          lastName: "de - Last Name",
          telephone: "de - Phone",
          jobTitle: "de - Job Title",
          company: "de - Company",
          address: "de - Address",
          zipCode: "de - Zip/Postal Code",
          city: "de - City/Town",
          country: "de - Country",
          state: "de - State",
          website: "de - Website",
          supportMessage: "de - Additional company information, e.g. Company website, DUNS number, Tax ID, Company ID etc.",
          contactCountry: "de - Country"
        },
        supportSendMessageSuccess: "de - Your email has been sent to support.",
        companyInformation: "de - Company Information",
        contactInformation: "de - Contact Information",
        message1:
          "de - Go back to edit the information or add additional information in the form below that will help us validate your company information. We will get back to you as soon as we have resolved the issue.",
        message2:
          "de - Please enter additional company information to help Milestone Support validate your company.",
      },
      overviewAndSubmission: {
        title: "de - Please review your information",
        description: {
          message1: "de - Please take a moment and review the information.",
          message2:
            "de - If you think everything looks good, please proceed to sign up, or else you can go back and edit your information.",
        },
      },
      submissionErrorNoAdmin: {
        title: "Sorry, we cannot activate your account",
        description: {
          message1: "Thank you for your interest in creating a My Milestone account. Unfortunately, there is no admin connected to {{selectedCompany}} so we cannot activate your account",
          message2: "We will contact you shortly to sort out the issue.",
          message3: "Sorry for the inconvenience.",
        },
      },
      submissionError: {
        title: "Sorry, we cannot activate your account",
        description: {
          message1: "Thank you for your interest in creating a My Milestone account. Unfortunately, an error has occurred and we could not activate your account.",
          message2: "Please <supportLink>contact support</supportLink>.",
          message3: "Reference: {{timestamp}}",
          message4: "Sorry for the inconvenience.",
        },
      },
      accountActivationInProcessError: {
        title: "Account activation in progress",
        description: {
          message1: "There is already a user or an active user request for this email."
        }
      },
      companyDescription: {
        whatIsCompany: "de - What do we define as a company?",
        title: "de - Company Definition",
        definition: {
          definition1:
            "de - The company name must be the legal entity name of your organization. The company name entered cannot be DBAs (Doing Business As), fictitious businesses, trade names, or branches for enrollment as a company/organization.",
          definition2:
            "de - Example: Company: International Business Machines Corp. (IBM should NOT be entered.)",
          definition3:
            "de - Please note that Milestone will not be trading with Private Individuals.",
        },
      },
      DUNSDescription: {
        whatIsDUNS: "de - What is a D-U-N-S number?",
        title: "de - D-U-N-S Number",
        definition: {
          definition1:
            "de - The D-U-N-S Number is a unique nine-digit number that identifies business entities on a location-specific basis. Assigned and maintained by Dun & Bradstreet (D&B), the D-U-N-S Number is widely used as a standard business identifier.",
          definition2:
            "de - If you know what your D-U-N-S Number is, please enter it. This will help us to check the identity and legal entity status of your organization as part of our enrollment verification process for using Milestone software or associated services and devices. ",
          definition3:
            "de - If you do not know your D-U-N-S number, please leave the field blank.",
        },
      },
      companyEmailGuide: "de - E.g. info@company-name.com",
    },
    resetPassword: {
      title: "de - Create or reset your password",
      description:
        "de - If you have forgotten your password please enter your user name below and click submit. Please note that your user name may be your email address.",
      descriptionAfterSubmission:
        "de - If your email address is found in our database you will receive an email shortly with a link to reset your password. Please follow the instructions in the email.",
      errorMessageBottom: "de - Create a login",
    },
    myDetails: {
      title: "de - My Details",
      stayInformedSection: {
        title: "de - Stay informed",
        subscribedSuccess: "de - Welcome, you are successfully opted-in.",
        subscribedMessageRich:
          "de - You are currently opted-in to receive marketing communication by emails and calls from <1>Milestone Group Companies</1>. You can unsubscribe at any time.",
        unsubscribedSuccess: "de - You have successfully unsubscribed.",
        unsubscribedMessageRich:
          "de - Do you want to hear from us? Please, opt-in if you like to receive marketing communications by electronic mails and calls from <companies>Milestone Group Companies</companies> regarding Milestone VMS and NVR products, services and activities, together with information about <partners>Milestone Partners</partners> and their Milestone related products, services and activities. You can <unsubscribe>unsubscribe</unsubscribe> at any time",
      },
      changePasswordSection: {
        title: "de - Change your password",
        successMessage: "de - Password has been updated successfully.",
      },
    },
    myUsers: {
      title: "de - User administration",
      description:
        "de - Here you can add, edit and delete users and grant access rights to users who have a My Milestone account within your company.",
      descriptionMilestoneEmployee:
        "Here you can edit and delete users and grant access rights to users who have a My Milestone account.",
      table: {
        globalFilterLabel: "de - Search...",
        columnLabels: {
          userName: "de - Username",
          firstName: "de - First Name",
          lastName: "de - Last Name",
          phone: "de - Phone",
          email: "de - Email",
          date: "de - Date",
        },
        pagination: {
          page: "de - Page",
          goToPage: "de - Go to page",
          show: "de - Show",
        },
      },
    },
    userDetails: {
      title: "de - User details",
      subtitleAddUser: "de - Add a new user",
      subtitleEditUser: "de - Edit user",
      description:
        "de - Here you can add, edit and delete users and grant access rights to users who have a My Milestone account within your company.",
      descriptionMilestoneEmployee:
        "Here you can edit and delete users and grant access rights to users who have a My Milestone account.",
      milestoneEmployeeEmailNote:
        "Please note that it is not possible to change the email address of a Milestone employee. Reach out to your local Sales Support team for assistance.​",
      titleSuccess: "de - Thank you for your request",
      descriptionSuccess: {
        message1:
          "de - An account activation link has been sent to the new user. Once the user has activated the account, it will be visible on your admin user list.",
        bestRegards: "de - Best regards,",
        milestoneTeam: "de - Milestone Systems Team",
      },
      rolesCheckboxesTitle: "de - User roles",
      noLongerWithCompanyCheckBoxesTitle:
        "de - Select in case this user is no longe part of your company",
    },
    adminAddNewUserRequest: {
      title: "de - User details",
      description:
        "de - Here you can add user and grant access to user who have a Milestone account with your company.",
      titleSuccess: "de - {{firstName}} {{lastName}} is created",
      descriptionSuccess:
        "de - {{firstName}} {{lastName}} has now been created as a user of {{companyName}}. We have notified {{firstName}} {{lastName}} via email and they can now log in to My Milestone using {{email}}.",
    },
    adminDecliNewUserApplication: {
      message1: "de - User request for {{fullname}} has been declined.",
      message2:
        "de - {{fullname}} will not be added as a My Milestone user for {{company}}.",
    },
    createNewPassword: {
      title: "de - Reset your password",
      titleSuccess: "de - Your password was changed successfully",
    },
    notFound: {
      message: "de - The current url does not exist...",
    },
    userAccountActivation: {
      title: "de - One more step",
      titleSuccess: "de - Your account is being reviewed",
      description: {
        message1:
          'de - Please complete your account setup by clicking on "Verify Email Address" below.',
        message2:
          "de - Once you have verified your email address and your account has been approved, you can start using My Milestone.",
      },
      descriptionSuccess:
        "de - Your email address has been verified and your account is being reviewed. You will receive an email from us, once we have reviewed your account.",
    },
    userTemporaryProfile: {
      title:
        "de - Fill in the form below to get access to My Milestone and activate your account",
      titleSuccess: "de - Your account has been created successfully",
      purposeTextRich:
        "de - By submitting this form you accept to be contacted by Milestone or a Milestone Partner. You may at any time withdraw your consent <1>here</1>.",
    },
    userUpdateEmail: {
      title: "de - Update Email Confirmation",
      description:
        "de - Your email has been updated successfully. Please go to <1>My Details</1> to see the changes.",
    },
    myLicenses: {
      title: "de - My Licenses",
      description:
        "de - This is an overview of the licenses that you have with Milestone Systems. Here you can see the license number, product name, product version and the status of your license.",
      table: {
        columnLabels: {
          slc: "de - License",
          productName: "de - Product/Version",
          status: "de - Status",
          careDate: "de - Care Date",
        },
      },
    },
    licenseClaim: {
      title: "de - Register Software License Code",
      titleSuccess: "de - Success",
      titleError:{
        license: "de - The license",
        couldNotRegister:"de - could not be registered."        
      },
      description:
        "de - Below you can register your available software license code (SLC).",
      descriptionSuccess: "de - You have successfully registered the software license code",
      descriptionError:
        "de - This is license is not valid for registering. Please check if you have already registered the license. To do this, check if the license is already present in the MY LICENSE overview link above, or button below. Or else, please check with your Milestone reseller or contact Milestone Support.",
        descriptionNoLicense: "de - No license available",
      licenseOverview:
        "You can see an overview of your licenses in the section called MY LICENSES.",
      register: "de - Register software license code",
      contactSupport:
        "de - If you cannot register the license and it’s not showing in the list on MY LICENSES, please contact your integrator, local Milestone reseller or",
    },
    verifyCompany: {
      title: "de - Your company has not been validated",
      titleSuccess: "de - Company data has been updated.",
      descriptionWithMatches:
        "de - To validate it now, please select your company below and click UPDATE. If you cannot find your company in the list, click 'My company is not on the list'",
      notNowDescription: "de - Click NOT NOW if you don't want to validate your company now",
      descriptionNoMatches:
        "de - Please check the information we currently have on you and your company:",
      descriptionSuccess:
        "de - Thank you! We have now updated your company data.",
      message1:
      "If any of the information is incorrect, please let us know in the message field below.",
      message2:
      "Please add any other information that can help our support team verify your company.",
    },
  },
  forms: {
    labels: {
      email: "de - Email {{required}}",
      username: "Username {{required}}",
      firstName: "de - First Name {{required}}",
      lastName: "de - Last Name {{required}}",
      telephone: "de - Phone number {{required}}",
      jobTitle: "de - Job Title {{required}}",
      company: "de - Company {{required}}",
      address: "de - Address {{required}}",
      zipCode: "de - Zip/Postal Code {{required}}",
      city: "de - City/Town {{required}}",
      vat: "de - Vat {{required}}",
      website: "de - Website {{required}}",
      password: "de - Create Password {{required}}",
      confirmPassword: "de - Confirm Password {{required}}",
      companyId: "de - Company Id {{required}}",
      mobile: "de - Mobile {{required}}",
      userName: "de - User Name {{required}}",
      oldPassword: "de - Old Password {{required}}",
      newPassword: "de - New Password {{required}}",
      confirmNewPassword: "de - Confirm New Password {{required}}",
      companyNumber: "de - D-U-N-S Number {{required}}",
      companyEmail: "Company email {{required}}",
    },
    consents: {
      marketingRich:
        "de - Ich möchte Marketingmitteilungen per E-Mail und Anrufe von <companies>Milestone Group Companies</companies> bezüglich VMS- und NVR-Produkten, Dienstleistungen und Aktivitäten von Milestone erhalten, zusammen mit Informationen über <partners>Milestone Partners</partners> und deren Milestone verwandte Produkte, Dienstleistungen und Aktivitäten. Mir ist bekannt, dass ich mich jederzeit abmelden <unsubscribe>unsubscribe</unsubscribe> kann.",
      marketing:
        "de - Ich möchte Marketingmitteilungen per E-Mail und Anrufe von Milestone Group Companies bezüglich VMS- und NVR-Produkten, Dienstleistungen und Aktivitäten von Milestone erhalten, zusammen mit Informationen über Milestone Partners und deren Milestone verwandte Produkte, Dienstleistungen und Aktivitäten. Mir ist bekannt, dass ich mich jederzeit abmelden unsubscribe kann.",
      termsConditionsRich:
        "de - Yes I agree to the <terms>Terms and Conditions</terms> regarding use of any products or services from Milestone Systems A/S.",
      termsConditions:
        "de - Yes I agree to the Terms and Conditions regarding use of any products or services from Milestone Systems A/S.",
    },
    milestoneEmployeeEmailUsernameNote:
      "de - Please note that it is not possible to change your email address. Reach out to your local sales support team for assistance.",
    privacyNoticeRich:
      "de - Milestone behandelt Ihre Kontaktdaten gemäß unserer <anchor>Datenschutzrichtlinie</anchor>. Hier finden Sie auch Informationen zu Ihren Datenschutzrechten und unserer Aufbewahrungsrichtlinie.",
    privacyNotice:
      "de - Milestone behandelt Ihre Kontaktdaten gemäß unserer Datenschutzrichtlinie. Hier finden Sie auch Informationen zu Ihren Datenschutzrechten und unserer Aufbewahrungsrichtlinie.",
    mandatoryFieldsInformation: "de - Fields marked with a * are mandatory.",
    adminInformation:
      "de - * MyMilestone Admin and License Admin are only granted to the primary contact unless otherwise specified by the reseller/distributor.",
    rolesInformation:
      "de - ** Webshop User and Webshop Quotes Only are determined by the distributor based on the contacts who should place orders and quotes and who should place quotes only on the Milestone Store.",
  },
  tables: {
    globalFilterLabel: "de - Search...",
    pagination: {
      page: "de - Page",
      goToPage: "de - Go to page",
      show: "de - Show",
    },
  },
  buttons: {
    signUp: "de - Sign up",
    goToLoginPage: "de - Go to login page",
    resetPassword: "de - Reset password",
    save: "de - Save",
    unsubscribeAll: "de - Unsubscribe all",
    contactMe: "de - Yes, you can contact me",
    addNewUser: "de - Add new user",
    cancel: "de - Cancel",
    backToList: "de - Back to user list",
    goToFrontPage: "de - Go to front page",
    goToMyMilestone: "de - Go to My Milestone",
    back: "de - back",
    next: "de - next",
    sendToMilestone: "de - Send to Milestone",
    backToHomepage: "de - Back to homepage",
    verifyEmail: "de - Verify Email Address",
    update: "de - Update",
    notNow: "de - not now",
    registerLicense: "de - register license",
    claimLicenseSuccessGoBack: "de - go back to my licenses",
    close: "de - close",
    continue: "de - Continue",
  },
  dialogs: {
    termsConditionsDialog: {
      title: "de - Terms & Conditions for My Milestone account",
      content: `
        <first>
            de - To access and register a Milestone product or services (“Milestone Product”) 
            you must access your My Milestone account. If you are a new user, you must either create a new 
            My Milestone account for your company or create a new user accessing a company My Milestone 
            account already existing.
        </first>
        </first>
        <second>
            de - If you create a My Milestone account on behalf of a company that is your
            employer or with which you are otherwise engaged as an administrator user,
            you represent and warrant that (i) you have full legal authority to bind
            the company to these terms and conditions (“Term and Conditions”) , (ii)
            you have read and understood the Terms and Conditions, and (iii) you agree
            to the Terms and Conditions on behalf of the company.
        </second>
        <third>
            de - Further, please be aware that as an administrator user you have access to
            the business information available on your company’s My Milestone account,
            and you are responsible for the general users you add to the My Milestone
            account. You need to make sure that such general users have the rights and
            are authorised to have access to this business information.
        </third>
        <fourth>
            de - If you are not an administrator user and you create a general user, you
            warrant that you have the legal authorization to do so and to bind your
            company to these Terms and Conditions and any other applicable terms as
            mentioned herein.
        </fourth>
        <fifth>
            de - The purpose of creating the My Milestone account is for Milestone to link
            the Milestone Product to the End-User of the Milestone Product, and for
            Milestone to be able to collect information about the End-User of the
            Milestone Product for compliance purposes such as authentication of the
            End-User as a licensee of the Milestone Product, including for the purpose
            of export control, as well as to enable Milestone to enforce license
            management and provide technical support where relevant. Milestone shall
            have the right to distribute information from the My Milestone account
            within the Milestone group for the purpose of fulfilling compliance
            obligations required by applicable laws, for research and development
            purposes, for analytical purposes, and for the purpose of marketing
            related activities if the consent to such marketing is granted by you as
            per below.
        </fifth>
        <sixth>
            de - At the My Milestone account you will have access to information on your
            Milestone Products, such as purchased licenses and Milestone Care. You are
            granted access to My Milestone via a username and password. Usernames and
            passwords are individual to you and must be stored in a safe place.
            Milestone reserves the right to revoke login credentials under the
            suspicion of misuse.
        </sixth>
        <seventh>
            de - You warrant that any information provided at the My Milestone account is
            true, complete, accurate, not misleading and up-to-date and acknowledge
            that Milestone relies upon the accuracy of the information provided. You
            undertake to promptly correct any such not correct information to ensure
            the information provided at My Milestone account is always up to date.
        </seventh>
        <eigth>
            de - When creating a My Milestone account and/or by using a Milestone Product
            you accept to have read and be bound by:
            <list>
                <item1>
                    de - These Terms and Conditions
                </item1>
                <item2>
                    de - The applicable <eulaLink>Milestone EULA</eulaLink> for the specific Milestone software
                    release.
                </item2>
                <item3>
                    de - If purchased, Care Terms and Conditions.
                </item3>
                <item4>
                    de - Any other terms and conditions applicable to your use of Milestone
                    products and/or service.
                </item4>
            </list>
        </eigth>
        <ninth>
            If you fail to comply with these Terms and Conditions and/or your
            Milestone Product is terminated according to the applicable Milestone
            EULA, Milestone may deactivate your My Milestone account.
        </ninth>
        <tenth>
            de - Milestone cares about the privacy of your personal data. Please read our
            <privacy>de - Privacy Policy (milestonesys.com)</privacy> which describes the types of personal data Milestone collects, the
            purposes and handling of your personal data in relation to your purchase
            of a Milestone Product.
        </tenth>
        `,
    },
  },
};

export default de;
